import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ThemeService } from './shared/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { CacheService } from './shared/services/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { it_IT, NzI18nService } from 'ng-zorro-antd/i18n';
import { it } from 'date-fns/locale';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})


export class AppComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private customService: ThemeService,
        private location: Location,
        private cacheService: CacheService,
        private translate: TranslateService,
        private i18n: NzI18nService
    ) { }

    isVisible = false;

    ngOnInit() {

        console.log(" ROUTE ", this.location.path());
        this.translate.setDefaultLang('it');
        this.i18n.setLocale(it_IT);
        this.i18n.setDateLocale(it);
        this.cacheService.theme = 'orange';
        /*
                if (window.location.href.includes('localhost')) {
                    this.customService.loadTheme(false, 'blue').then();
                    this.cacheService.theme = 'blue';
                }
                
                if (window.location.href.includes('dashboard')) {
                    this.customService.loadTheme(false, 'orange').then();
                    this.cacheService.theme = 'orange';
                }*/

        

    }
}
