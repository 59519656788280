import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoPersona'
})
export class TipoPersonaPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value === 'PersonaFisica') {
      return 'Persona fisica';
    } else {
      return 'Persona giuridica'
    }
  }

}
