import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { Codebook } from 'src/app/codebooks/codebook.models';
import { CodebookServices } from 'src/app/codebooks/codebook.services';
import { Anagrafica, Documento, Parte, PersonaFisica } from 'src/app/generated/mediazione/models';
import { AzioniFacoltativeOptionsComponent } from 'src/app/main-layout/reserved-area/mediazioni/mediazione-process/mediazione-process-container/azioni-facoltative-options/azioni-facoltative-options.component';
import { RicercaProtocolloComponent } from 'src/app/main-layout/reserved-area/mediazioni/ricerca-protocollo/ricerca-protocollo.component';
import { environment } from 'src/environments/environment';
import { Panel } from '../components/anagrafica-parte/anagrafica-parte.component';
import { AvvocatoFormData } from '../components/anagrafica-parte/avvocato-form/avvocato-form.component';
import { ParteFormData } from '../components/anagrafica-parte/parte-form/parte-form.component';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  URL_UPLOAD = '/api/documents/upload';
  URL_TEMPLATE = '/api/documents/template/upload';
  URL_PRIVACY = '/api/documents/privacy/upload'
  URL_DOWNLOAD = '/api/documents/download';
  ASSEGNAZIONE_MEDIATORE = 'ASSEGNAZIONE-MEDIATORE';
  PROTOCOLLO = 'PROTOCOLLAZIONE';
  NOME_ODM = 'NOME-ODM';
  INDIRIZZO_ODM = 'INDIRIZZO-ODM';
  PEC_ODM = 'PEC-ODM';
  MAIL_ODM = 'MAIL-ODM';
  URL = 'URL';
  PRIVACY = 'PRIVACY';
  TIMEOUT_NOTIFICHE = 30000; // 30 secondi

  constructor(
    private router: Router,
    private http: HttpClient,
    private codebook: CodebookServices
  ) {}

  returnToMediazioneList() {
    this.router.navigate(['/home/lista-mediazioni'], {
      queryParams: { tab: 1, subTab: 0 },
    });
  }

  getValuesForSpeseMediazioneAndSpeseAvvio(val: number): number {
    return val >= 250000 ? 80.0 : 40.0;
  }

  transformParteIntoPanels(parte: Parte, isAdesione: boolean): Panel[] {
    const panels: Panel[] = [];
    panels[0] = {
      active: true,
      name: 'PARTE',
      isParte: true,
      canBeDeleted: false,
      formData: parte.datiAnagrafici.tipoPersona === 'PersonaFisica' ? { ...(parte.datiAnagrafici as AvvocatoFormData) } : { ...(parte.datiAnagrafici as ParteFormData) },
      costituito: parte.costituito,
    };
    if (parte.avvocati) {
      for (const avvocato of parte.avvocati) {
        panels.push({
          active: false,
          name: 'AVVOCATO',
          isParte: false,
          canBeDeleted: isAdesione ? true : false,
          formData: { ...(avvocato.datiAnagrafici as AvvocatoFormData) },
        });
      }
    }
    return panels;
  }

  download(id: string, nome: string) {
    const url = environment.be_url + this.URL_DOWNLOAD;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('key', id);
    this.http.get(url, { responseType: 'blob' as 'json', params: queryParams }).subscribe((response: any) => {
      let dataType = response.type;
      console.log(' tipo  ', dataType);
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/octet-stream' }));
      if (nome) downloadLink.setAttribute('download', nome);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    const name: string = Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
    return name;
  }

  closeModal(reference: RicercaProtocolloComponent, modalPropertyList: string[]) {
    for (const iterator of modalPropertyList) {
      reference[iterator] = false;
    }
  }

  areThePreviousFormsValid(reference: RicercaProtocolloComponent | AzioniFacoltativeOptionsComponent, panels: Panel[]): boolean {
    let form: UntypedFormGroup;
    for (const iterator of panels) {
      iterator.active = false;
    }
    for (let index = 0; index < panels.length; index++) {
      form = this.getSelectedForm(reference, index);
      if (form.invalid) {
        panels[index].active = true;
        Object.values(form.controls).forEach((control) => { if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
          return;
        }});
        return false;
      }
    }
    return true;
  }

  shouldItAddALawyer(panels: Panel[], reference: RicercaProtocolloComponent | AzioniFacoltativeOptionsComponent): boolean {
    if (panels.length === 1) {
      let form: UntypedFormGroup;
      for (const iterator of reference.parte.panels) {
        iterator.active = false;
      }
      form = reference.parte.parteForm.parteForm;
      if (form.get('tipoPersona').value !== 'PersonaGiuridica') form = reference.parte.parteForm.avvocatoForm.form;
      if (form.get('rappresentata').value === true) return true;
    }
    return false;
  }

  getSelectedForm(reference: RicercaProtocolloComponent | AzioniFacoltativeOptionsComponent, index: number): UntypedFormGroup {
    let form: UntypedFormGroup;
    const parteToUse = reference.isAdesioneVisible ? reference.adesione.parte : reference.parte;
    if (parteToUse.panels[index].isParte) {
      form = parteToUse.parteForm.parteForm;
      if (form.get('tipoPersona').value !== 'PersonaGiuridica') form = parteToUse.parteForm.avvocatoForm.form;
    } else {
      form = parteToUse.avvocatoForm.toArray()[index - 1].form;
    }
    return form;
  }

  addLawyer(reference: RicercaProtocolloComponent | AzioniFacoltativeOptionsComponent) {
    const parteToUse = reference.isAdesioneVisible ? reference.adesione.parte : reference.parte;
    if (this.areThePreviousFormsValid(reference, parteToUse.panels)) {
      const panels = parteToUse.panels;
      for (const panel of panels) {
        panel.active = false;
      }
      panels.splice(panels.length, 0, {
        active: true,
        isParte: false,
        name: 'AVVOCATO',
        canBeDeleted: true,
        isANewLawyerBeingAdded: reference.isAdesioneVisible ? true : null
      });
    }
  }

  prepareParte(reference: AzioniFacoltativeOptionsComponent | RicercaProtocolloComponent, parteOrigin: 'PROPONENTE' | 'RESISTENTE' | 'INTERVENUTO' | 'CHIAMATO'): Parte {
    let preparedParte: Parte = { datiAnagrafici: {} as Anagrafica, tipo: parteOrigin, avvocati: [] };
    let form: UntypedFormGroup;
    const propertyList: string[] = [
      'tipoPersona',
      'denominazione',
      'partitaIva',
      'tipologia',
      'numeroTelefono',
      'cittaResidenza',
      'provinciaResidenza',
      'capResidenza',
      'codiceFiscale',
      'nome',
      'cognome',
      'dataNascita',
      'cittaNascita',
      'provinciaNascita',
      'indirizzoResidenza',
      'documenti',
      'pec',
      'email',
      'albo',
      'ordine'
    ];
    const parteToUse = reference.isAdesioneVisible ? reference.adesione.parte : reference.parte;
    for (let index = 0; index < parteToUse.panels.length; index++) {
      form = this.getSelectedForm(reference, index);
      if (index !== 0) preparedParte.avvocati.push({ datiAnagrafici: {} as PersonaFisica });
      Object.values(form.controls).forEach((control) => {
        const controlName = this.getControlName(control);
        if (index === 0) {
          if (propertyList.includes(controlName)) {
            if (controlName === 'documenti') {
              preparedParte.idDocumentiInput = control.value ? control.value?.map((element: Documento) => { return element.nomeFile; }) : null;
            } else {
              preparedParte.datiAnagrafici[controlName] = control.value;
            }
          }
        } else {
          if (propertyList.includes(controlName)) {
            if (controlName === 'documenti') {
              preparedParte.avvocati[index - 1].idDocumentiInput = control.value?.map((element: Documento) => { return element.nomeFile; });
            } else {
              preparedParte.avvocati[index - 1].datiAnagrafici[controlName] = control.value;
            }
          }
        }
      });
    }
    return preparedParte;
  }

  getDateAccordingToTheFormat(date: Date): string {
    return new Date(date.getTime() - date.getTimezoneOffset() * 6000).toISOString();
  }

  getDateWithoutHour(dateString: string): string {
    return dateString.split('T')[0];
  }

  getDropdownValues(): Observable<{ comuni: Codebook[], province: Codebook[], stati: Codebook[] }> {
    return forkJoin({
      comuni: this.codebook.getComuni(),
      province: this.codebook.getProvinces(),
      stati: this.codebook.getStati(),
    });
  }
}
