import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, it_IT } from 'ng-zorro-antd/i18n';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';

import {
  registerLocaleData,
  LocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import en from '@angular/common/locales/en';
import it from '@angular/common/locales/it';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';

import { ThemeConstantService } from './shared/services/theme-constant.service';
import { ThemeService } from './shared/services/theme.service';
import { ApiInterceptor } from './api.interceptor';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AuthGuard } from './authentication/auth.guard';
import { environment } from 'src/environments/environment';
import { ErrorInterceptor } from './error.interceptor';
import { MediazioneApiModule } from './generated/mediazione/mediazione-api.module';
import { ConfigurationService } from './configuration.service';
import { ApiModuleConf } from './api.module.conf';
import { apiModuleOptions } from './api.conf.variable';
import { Tenant } from './configuration';


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

registerLocaleData(it);

export const AppInitializerThemeProvider = {
  provide: APP_INITIALIZER,
  useFactory: (themeService: ThemeService) => () => {
    return themeService.loadTheme(false, 'blue');
  },
  deps: [ThemeService],
  multi: true,
};

const antdModule = [NzMenuModule, NzLayoutModule];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    KeycloakAngularModule,
    MediazioneApiModule.forRoot(apiModuleOptions),
    ...antdModule,
  ],
  providers: [
    AppInitializerThemeProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: NZ_I18N,
      useValue: it_IT,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    ThemeConstantService,
    AuthGuard,
  ],
})
export class AppModule {
  constructor(
    private configService: ConfigurationService,
    private keycloakService: KeycloakService
  ) { }

  ngDoBootstrap(app) {
     this.configService.loadConfigurations().subscribe(res => {
      const properties = res;

      const hostnameToFind = window.location.hostname;
      const tenantId = properties.propertySources[0].source.m4o.multitenancy.tenants.find((t: Tenant) => t.hostname === hostnameToFind)?.tenantId;
   
      if(tenantId) {localStorage.setItem('realm',tenantId)}

      apiModuleOptions.rootUrl = properties.propertySources[0].source.m4o.backend.url;
      this.keycloakService.init({
        config: {
          url: `${properties.propertySources[0].source.m4o.keycloak.url}/`,
          realm: tenantId,
          clientId: properties.propertySources[0].source.m4o.keycloak.client
        },

        loadUserProfileAtStartUp: false,
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/verify-sso.html',
          checkLoginIframe: false,
        },
      })
        .then(() => {
          app.bootstrap(AppComponent);
        })
        .catch((error) => console.error('[ngDoBootstrap] init failed', error));
    });
  }
}
