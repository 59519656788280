import { Pipe, PipeTransform } from '@angular/core';
import { Giorno } from 'src/app/generated/mediazione/models';

@Pipe({
  name: 'giorno',
})
export class GiornoPipe implements PipeTransform {
  transform(value: Giorno): unknown {
    switch (value) {
      case 'LUNEDI':
        return 'Lunedì';
        break;
      case 'MARTEDI':
        return 'Martedì';
      case 'MERCOLEDI':
        return 'Mercoledì';
      case 'GIOVEDI':
        return 'Giovedì';
      case 'VENERDI':
        return 'Venerdì';
      default:
        return 'Sabato';
    }
  }
}
