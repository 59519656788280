import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IncontroMediazione } from 'src/app/generated/mediazione/models';
import { MediatoreIncontriService } from 'src/app/generated/mediazione/services';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss'],
})
export class CustomCalendarComponent implements OnInit {
  loading: boolean = true;

  daysOfTheWeek: string[] = [
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
    'Domenica',
  ];

  date = new Date();

  dateList: {
    value: Date;
    isSelected: boolean;
    areThereIncontri: boolean;
    incontriList?: IncontroMediazione[];
  }[] = [];

  @Input() mediatoreId: string;

  @Output() onClickCellEvent: EventEmitter<{
    incontriList: IncontroMediazione[];
  }> = new EventEmitter<{ incontriList: IncontroMediazione[] }>();

  constructor(
    private mediatoreIncontriService: MediatoreIncontriService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.dateList.push({
      value: this.date,
      isSelected: true,
      areThereIncontri: false,
      incontriList: [],
    });

    while (this.dateList.length < 7) {
      let selectedDayIndex =
        this.dateList.findIndex(
          (element: { value: Date; isSelected: boolean }) => {
            return element.isSelected === true;
          }
        ) + 1;

      if (selectedDayIndex < this.date.getDay()) {
        const value = this.removeOneDay(this.dateList[0].value);
        this.dateList.unshift({
          value,
          isSelected: false,
          areThereIncontri: false,
          incontriList: [],
        });
      }
      if (selectedDayIndex === this.date.getDay()) {
        const value = this.addOneDay(
          this.dateList[this.dateList.length - 1].value
        );
        this.dateList.push({
          value,
          isSelected: false,
          areThereIncontri: false,
          incontriList: [],
        });
      }
    }

    this.getAllIncontris();
  }

  addOneDay(date = new Date()) {
    const dateCopy: Date = new Date(date);

    dateCopy.setDate(dateCopy.getDate() + 1);

    return dateCopy;
  }

  removeOneDay(date = new Date()) {
    const dateCopy: Date = new Date(date);

    dateCopy.setDate(dateCopy.getDate() - 1);

    return dateCopy;
  }

  getLocalDay(date: Date): number {
    return !date.getDay() ? 7 : date.getDay();
  }

  goToNextDays() {
    const lastDayOfWeek: Date = this.dateList[this.dateList.length - 1].value;

    this.dateList = [];

    for (let index = 0; index < 7; index++) {
      let value: Date;
      if (index === 0) {
        value = this.addOneDay(lastDayOfWeek);
      } else {
        value = this.addOneDay(this.dateList[this.dateList.length - 1].value);
      }

      this.dateList.push({
        value,
        isSelected: false,
        areThereIncontri: false,
        incontriList: [],
      });
    }

    this.loading = true;
    this.getAllIncontris();
  }

  goToPreviousDays() {
    const firstDayOfTheWeek: Date = this.dateList[0].value;

    this.dateList = [];

    for (let index = 0; index < 7; index++) {
      let value: Date;
      if (index === 0) {
        value = this.removeOneDay(firstDayOfTheWeek);
      } else {
        value = this.removeOneDay(this.dateList[0].value);
      }

      this.dateList.unshift({
        value,
        isSelected: false,
        areThereIncontri: false,
        incontriList: [],
      });
    }

    this.loading = true;
    this.getAllIncontris();
  }

  onClickCell(selectedCell: {
    value: Date;
    isSelected: boolean;
    areThereIncontri: boolean;
    incontriList?: IncontroMediazione[];
  }) {
    this.onClickCellEvent.emit({ incontriList: selectedCell.incontriList });
  }

  getAllIncontris() {
    this.mediatoreIncontriService
      .getIncontriMediatore({
        search: {
          idMediatore: this.mediatoreId,
          da: this.utilsService.getDateAccordingToTheFormat(
            this.dateList[0].value
          ),
          al: this.utilsService.getDateAccordingToTheFormat(
            this.dateList[this.dateList.length - 1].value
          ),
        },
      })
      .subscribe(
        (data: IncontroMediazione[]) => {
          this.populateIncontris(data);
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  populateIncontris(incontriList: IncontroMediazione[]) {
    if (incontriList.length > 0) {
      for (const incontri of incontriList) {
        for (const iterator of this.dateList) {
          if (
            this.utilsService.getDateWithoutHour(
              this.utilsService.getDateAccordingToTheFormat(
                new Date(incontri.data)
              )
            ) ===
            this.utilsService.getDateWithoutHour(
              this.utilsService.getDateAccordingToTheFormat(iterator.value)
            )
          ) {
            iterator.areThereIncontri = true;
            iterator.incontriList.push(incontri);
          }
        }
      }
    }

    this.loading = false;
  }
}
