import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Configuration, Tenant } from './configuration';
import * as _ from "lodash";
import { Config } from 'protractor';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

    private CONFIG_SERVER = environment.configServer;
    private configuration$: Observable<Configuration>;

    constructor(private http: HttpClient) {
    }

    public loadConfigurations(): Observable<Configuration> {
        if (!this.configuration$) {
            this.configuration$ = this.http.get<Configuration>(`${this.CONFIG_SERVER}/mediazione-frontend/${environment.production ? 'prd' : 'dev'}`)
            .pipe(
                map(obj=> {
                    let source: Configuration = {propertySources: [{ source:{} }]};
                    
                    for (const [key, value] of Object.entries(obj.propertySources[0].source)) {
                        _.set(source.propertySources[0].source, key, value);
                      }
                      obj.propertySources[0].source = source.propertySources[0].source;

                      return obj;
                }),
                shareReplay(1)
            );


        }
        return this.configuration$;
    }

    public getConfiguration(): Observable<Configuration> {
        return this.configuration$;
    }

}