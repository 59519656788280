/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Parte } from '../models/parte';
import { Utente } from '../models/utente';

@Injectable({
  providedIn: 'root',
})
export class UtentiEsterniService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation saveNewEsterno
   */
  static readonly SaveNewEsternoPath = '/api/utenti/esterni';

  /**
   * insert new Utente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveNewEsterno()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveNewEsterno$Response(params: {
    body: Utente
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Utente>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiEsterniService.SaveNewEsternoPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Utente>;
      })
    );
  }

  /**
   * insert new Utente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveNewEsterno$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveNewEsterno(params: {
    body: Utente
  },
  context?: HttpContext

): Observable<Utente> {

    return this.saveNewEsterno$Response(params,context).pipe(
      map((r: StrictHttpResponse<Utente>) => r.body as Utente)
    );
  }

  /**
   * Path part for operation getParteByMediazione
   */
  static readonly GetParteByMediazionePath = '/api/utenti/esterni/parte/mediazione/{id}';

  /**
   * loads the parte associated with the mediazione id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParteByMediazione()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParteByMediazione$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Parte>>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiEsterniService.GetParteByMediazionePath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Parte>>;
      })
    );
  }

  /**
   * loads the parte associated with the mediazione id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParteByMediazione$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParteByMediazione(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Array<Parte>> {

    return this.getParteByMediazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Parte>>) => r.body as Array<Parte>)
    );
  }

}
