<footer [class.full-layout-footer]="fullLayout" [class.common-layout-footer]="!fullLayout"
    class="full-layout-footer position-footer">
    <div class="row py-2">
        <div class="col-4 d-flex align-items-center">
            <img alt="Logo" src="assets//images/logo/real-logo.png" height="40px" />
        </div>
        <div class="col-4 d-flex justify-content-center align-items-end">
            <span class="pb-3 copyright-text text-dark">
                <span nz-icon nzType="copyright-circle" nzTheme="outline">
                    <ul class="footer-links">
                        <li>{{ nome }}</li>
                        <li>{{ indirizzo }}</li>
                        <li>{{ pec }}</li>
                        <li>{{ mail }}</li>
                    </ul>
                </span>
            </span>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end">
            <ul class="footer-links">
                <li class="text-dark">CONTATTI</li>
                <li class="text-dark">FAQs</li>
                <li class="text-dark"><a (click)="goToPrivacy()">PRIVACY POLICY</a></li>
                <li class="text-dark">MANUALISTICA</li>
            </ul>
        </div>
    </div>
</footer>