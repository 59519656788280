<div class="row">
  <div class="col-12">
    <div class="w-100 h-100 border p-3 rounded">
      <div class="row">
        <div class="col-12">
          <h4>Luigi Bianchi</h4>
        </div>
        <div class="col-12 mt-2">
          <nz-table
            class="open-table-extend-footer"
            #notificationDetailsDataTable
            [nzData]="dataSource"
            [nzFrontPagination]="false"
            [nzLoading]="loading"
            [nzTotal]="total"
            [nzPageSize]="pageSize"
            [nzPageIndex]="pageIndex"
            nzTableLayout="auto"
            nzShowSizeChanger
            [nzPageSizeOptions]="listPages"
            [nzScroll]="{ x: '100we' }"
            (nzQueryParams)="onQueryParamsChange($event)"
          >
            <thead>
              <tr>
                <th
                  class="px-2"
                  [nzColumnKey]="column.property"
                  [nzShowSort]="column.sort"
                  [nzSortFn]="column.sort"
                  [nzWidth]="column.width"
                  *ngFor="let column of columnList"
                >
                  {{ column.code }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of notificationDetailsDataTable.data">
                <td
                  class="px-2"
                  *ngFor="let column of columnList"
                >
                  <ng-container *ngIf="column['property'] === 'file'">
                    <ng-container *ngIf="data[column['property']]">
                      <img
                        src="../../../../../../assets/images/PDF_file_icon.svg"
                        width="14px"
                        height="17px"
                        alt="PDF File SVG"
                      />
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="column['property'] !== 'file'">
                    <ng-container
                      *ngIf="column['property'] === 'status'"
                      [ngSwitch]="data[column['property']]"
                    >
                      <span
                        nz-icon
                        nzType="warning"
                        nzTheme="fill"
                        class="warning-icon"
                        *ngSwitchCase="'WARNING'"
                      ></span>
                      <span
                        nz-icon
                        nzType="clock-circle"
                        nzTheme="fill"
                        class="waiting-icon"
                        *ngSwitchCase="'WAITING'"
                      ></span>
                      <span
                        nz-icon
                        nzType="check-circle"
                        nzTheme="fill"
                        class="done-icon"
                        *ngSwitchDefault
                      ></span>
                    </ng-container>
                    <ng-container *ngIf="column['property'] !== 'status'">
                      <ng-container *ngIf="column['property'] === 'ruoli'">
                        <ng-container
                          *ngFor="let type of data[column['property']]"
                        >
                          <nz-tag
                            [nzColor]="'geekblue'"
                            *ngIf="type === 'SEGRETARIO'"
                          >
                            <div>SEG</div>
                          </nz-tag>
                          <nz-tag
                            [nzColor]="'green'"
                            *ngIf="type === 'AMMINISTRATORE'"
                          >
                            <div>ADM</div>
                          </nz-tag>
                          <nz-tag
                            [nzColor]="'magenta'"
                            *ngIf="type === 'MEDIATORE'"
                          >
                            <div>MED</div>
                          </nz-tag>
                          <nz-tag [nzColor]="'gold'" *ngIf="type === 'parte'">
                            <div>PAR</div>
                          </nz-tag>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="column['property'] !== 'ruoli'">
                        {{ data[column["property"]] }}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>
