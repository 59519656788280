import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  NotificationOutput,
  Notification,
  Pagination,
  Sorting,
  SortDir,
  NotificationStatus,
  Utente,
} from 'src/app/generated/mediazione/models';
import {
  NotificationService,
  ProfiloService,
} from 'src/app/generated/mediazione/services';

@Component({
  selector: 'app-tutte-notifiche',
  templateUrl: './tutte-notifiche.component.html',
  styleUrls: ['./tutte-notifiche.component.scss'],
  providers: [DatePipe],
})
export class TutteNotificheComponent implements OnInit {
  dataSource: Notification[] = [];
  originalDataSource: Notification[] = [...this.dataSource];
  loading = false;
  pageSize = 5;
  pageIndex = 1;
  total = 0;
  listPages = [2, 5, 10, 20, 30];
  columnList: {
    code: string;
    sort: boolean;
    property: string;
    width: string;
  }[] = [
      { code: 'Data', property: 'creationDate', sort: true, width: '160px' },
      { code: 'Dettaglio', property: 'description', sort: false, width: null },
    ];
  searchValue: string = null;
  allChecked: boolean = false;
  anagraphica_id: string = null;

  @Input() origin: 'Mediazione' | 'Notification';
  @Input() mediazione_id: string;

  @Output() reloadCounter: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() goToMediazioneEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private notificationService: NotificationService,
    private profiloService: ProfiloService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.columnList.unshift({
      code: 'Status',
      property: 'status',
      sort: false,
      width: '100px',
    });

    if (this.origin === 'Notification') {
      this.columnList.push({
        code: 'Mediazione',
        property: 'mediazione',
        sort: false,
        width: '180px',
      });
    }

  }

  onQueryParamsChange(params): void {
    this.loading = true;
    const currentSort = params.sort?.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    let sort: Sorting;

    this.pageIndex = params.pageIndex;
    this.pageSize = params.pageSize;

    if (sortField !== null) {
      sort = {
        dir: sortOrder === 'ascend' ? SortDir.Asc : SortDir.Desc,
        sorted: ['notification.' + sortField],
      };
    }

    const parameters: {
      anagraphica_id?: string;
      mediazione_id?: string;
      sort?: null | Sorting;
      page?: null | Pagination;
    } = {
      page: {
        page: this.pageIndex - 1,
        size: this.pageSize,
      },
      sort,
    };

    if (this.origin === 'Mediazione') {
      parameters.mediazione_id = this.mediazione_id;
    }

    console.log("8");
    this.getUser({ page: parameters.page, sort: parameters.sort });
  }

  filterData() {
    if (this.searchValue === '' || this.searchValue === null) {
      this.dataSource = [...this.originalDataSource];
    } else {
      this.dataSource = this.originalDataSource.filter(
        (element: Notification) => {
          return element.description
            .toLocaleLowerCase()
            .includes(this.searchValue.toLocaleLowerCase());
        }
      );
    }
  }

  makeSearchValueNull() {
    this.searchValue = null;
    this.dataSource = [...this.originalDataSource];
  }

  goToTheSpecifiedMediazione(mediazione: string) {
    this.router.navigate([`/home/processo-mediazione/${mediazione}`]);
    this.goToMediazioneEvent.emit(true);
  }

  getAllNotifications(params?: {
    anagraphica_id?: string;
    mediazione_id?: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  }) {
    console.log("4");
    this.notificationService
      .getNotifications(params)
      .subscribe((response: NotificationOutput) => {
        if (response) {
          this.total = response.totalElements;
          this.originalDataSource = JSON.parse(JSON.stringify(response.list));
          this.dataSource = response.list;
          this.allChecked = this.dataSource.filter(nn => !nn.status)?.length === 0;
        }
        this.loading = false;
      });
  }

  checkAll(event: boolean) {
    if (event && this.total > 0) {
      this.loading = true;
      this.dataSource = this.dataSource.map((element: Notification) => {
        return {
          ...element,
          status: true,
        };
      });

      this.notificationService
        .updateAnagraficaNotificationsStatus({
          anagraficaId: this.anagraphica_id,
          status: NotificationStatus.Seen,
        })
        .subscribe(() => {
          console.log("5");
          this.getAllNotifications({
            page: {
              page: this.pageIndex - 1,
              size: this.pageSize,
            },
            anagraphica_id: this.anagraphica_id,
            mediazione_id: this.mediazione_id,
          });
          this.reloadCounter.emit(true);
        });
    }
  }

  getUser(params: {
    sort?: null | Sorting;
    page?: null | Pagination;
  }) {
    this.loading = true;
    this.profiloService.getProfile().subscribe((data: Utente) => {
      this.anagraphica_id = data.idAnagrafica;
      console.log("6");
      this.getAllNotifications({
        page: params?.page,
        sort: params?.sort,
        anagraphica_id: this.anagraphica_id,
        mediazione_id: this.mediazione_id,
      });
    });
  }

  updateNotification(data: Notification) {
    if (data.status) {
      this.loading = true;
      this.notificationService
        .updateNotificationStatus({
          id: data.notificationId,
          status: data.status,
        })
        .subscribe(() => {
          console.log("7");
          this.getAllNotifications({
            page: {
              page: this.pageIndex - 1,
              size: this.pageSize,
            },
            anagraphica_id: this.anagraphica_id,
            mediazione_id: this.mediazione_id,
          });
          this.reloadCounter.emit(true);
        });
    }
  }



}
