/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IncontroMediatoreSearch } from '../models/incontro-mediatore-search';
import { IncontroMediazione } from '../models/incontro-mediazione';

@Injectable({
  providedIn: 'root',
})
export class MediatoreIncontriService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getIncontriMediatore
   */
  static readonly GetIncontriMediatorePath = '/api/mediatore/incontri';

  /**
   * loads the incontrii associated to the mediatore.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncontriMediatore()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncontriMediatore$Response(params?: {
    search?: IncontroMediatoreSearch;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<IncontroMediazione>>> {

    const rb = new RequestBuilder(this.rootUrl, MediatoreIncontriService.GetIncontriMediatorePath, 'get');
    if (params) {
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IncontroMediazione>>;
      })
    );
  }

  /**
   * loads the incontrii associated to the mediatore.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIncontriMediatore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncontriMediatore(params?: {
    search?: IncontroMediatoreSearch;
  },
  context?: HttpContext

): Observable<Array<IncontroMediazione>> {

    return this.getIncontriMediatore$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<IncontroMediazione>>) => r.body as Array<IncontroMediazione>)
    );
  }

}
