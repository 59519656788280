/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Descriptor } from '../models/descriptor';
import { Mediazione } from '../models/mediazione';
import { Payload } from '../models/payload';

@Injectable({
  providedIn: 'root',
})
export class ActionsSegretarioService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation executeActionSegretario
   */
  static readonly ExecuteActionSegretarioPath = '/api/segretario/actions';

  /**
   * execute Action.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeActionSegretario()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeActionSegretario$Response(params: {
    body: Payload
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mediazione>> {

    const rb = new RequestBuilder(this.rootUrl, ActionsSegretarioService.ExecuteActionSegretarioPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mediazione>;
      })
    );
  }

  /**
   * execute Action.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeActionSegretario$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  executeActionSegretario(params: {
    body: Payload
  },
  context?: HttpContext

): Observable<Mediazione> {

    return this.executeActionSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mediazione>) => r.body as Mediazione)
    );
  }

  /**
   * Path part for operation getActionsMediazioneSegretario
   */
  static readonly GetActionsMediazioneSegretarioPath = '/api/segretario/actions/{id}';

  /**
   * load the actions related to a specific Mediazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActionsMediazioneSegretario()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActionsMediazioneSegretario$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Descriptor>>> {

    const rb = new RequestBuilder(this.rootUrl, ActionsSegretarioService.GetActionsMediazioneSegretarioPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Descriptor>>;
      })
    );
  }

  /**
   * load the actions related to a specific Mediazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActionsMediazioneSegretario$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActionsMediazioneSegretario(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Array<Descriptor>> {

    return this.getActionsMediazioneSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Descriptor>>) => r.body as Array<Descriptor>)
    );
  }

}
