/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UtenteDocumentiService } from './services/utente-documenti.service';
import { UtentiService } from './services/utenti.service';
import { MediazioneUtentiService } from './services/mediazione-utenti.service';
import { UtentiEsterniService } from './services/utenti-esterni.service';
import { ProfiloService } from './services/profilo.service';
import { AnagraficaService } from './services/anagrafica.service';
import { SegretarioUtenteService } from './services/segretario-utente.service';
import { UtenteService } from './services/utente.service';
import { MediazioneSegretarioService } from './services/mediazione-segretario.service';
import { MateriaMediatoreService } from './services/materia-mediatore.service';
import { ActionsUtentiService } from './services/actions-utenti.service';
import { ActionsSegretarioService } from './services/actions-segretario.service';
import { AlarmsSegretarioService } from './services/alarms-segretario.service';
import { AlarmsUtentiService } from './services/alarms-utenti.service';
import { DocumentiService } from './services/documenti.service';
import { UtenteDisponibilitaService } from './services/utente-disponibilita.service';
import { UtentePubblicoService } from './services/utente-pubblico.service';
import { ListaSpeseService } from './services/lista-spese.service';
import { CalcolaSpeseService } from './services/calcola-spese.service';
import { ConfigurazioneSpeseService } from './services/configurazione-spese.service';
import { ConfigurazioneService } from './services/configurazione.service';
import { MediatoreIncontriService } from './services/mediatore-incontri.service';
import { FormazioneService } from './services/formazione.service';
import { NotificationService } from './services/notification.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UtenteDocumentiService,
    UtentiService,
    MediazioneUtentiService,
    UtentiEsterniService,
    ProfiloService,
    AnagraficaService,
    SegretarioUtenteService,
    UtenteService,
    MediazioneSegretarioService,
    MateriaMediatoreService,
    ActionsUtentiService,
    ActionsSegretarioService,
    AlarmsSegretarioService,
    AlarmsUtentiService,
    DocumentiService,
    UtenteDisponibilitaService,
    UtentePubblicoService,
    ListaSpeseService,
    CalcolaSpeseService,
    ConfigurazioneSpeseService,
    ConfigurazioneService,
    MediatoreIncontriService,
    FormazioneService,
    NotificationService,
    ApiConfiguration
  ],
})
export class MediazioneApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<MediazioneApiModule> {
    return {
      ngModule: MediazioneApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: MediazioneApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('MediazioneApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
