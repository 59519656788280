/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Pagination } from '../models/pagination';
import { Sorting } from '../models/sorting';
import { UtenteOutput } from '../models/utente-output';
import { UtenteSearch } from '../models/utente-search';

@Injectable({
  providedIn: 'root',
})
export class UtenteService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAllMediatori
   */
  static readonly FindAllMediatoriPath = '/api/utenti/mediatore';

  /**
   * load the list of utenti Mediatori.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllMediatori()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllMediatori$Response(params?: {
    search?: UtenteSearch;
    sorting?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UtenteOutput>> {

    const rb = new RequestBuilder(this.rootUrl, UtenteService.FindAllMediatoriPath, 'get');
    if (params) {
      rb.query('search', params.search, {"explode":true});
      rb.query('sorting', params.sorting, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UtenteOutput>;
      })
    );
  }

  /**
   * load the list of utenti Mediatori.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllMediatori$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllMediatori(params?: {
    search?: UtenteSearch;
    sorting?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<UtenteOutput> {

    return this.findAllMediatori$Response(params,context).pipe(
      map((r: StrictHttpResponse<UtenteOutput>) => r.body as UtenteOutput)
    );
  }

  /**
   * Path part for operation findAllEsperti
   */
  static readonly FindAllEspertiPath = '/api/utenti/esperto';

  /**
   * load the list of utenti esperti.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllEsperti()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllEsperti$Response(params?: {
    search?: UtenteSearch;
    sorting?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UtenteOutput>> {

    const rb = new RequestBuilder(this.rootUrl, UtenteService.FindAllEspertiPath, 'get');
    if (params) {
      rb.query('search', params.search, {"explode":true});
      rb.query('sorting', params.sorting, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UtenteOutput>;
      })
    );
  }

  /**
   * load the list of utenti esperti.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllEsperti$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllEsperti(params?: {
    search?: UtenteSearch;
    sorting?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<UtenteOutput> {

    return this.findAllEsperti$Response(params,context).pipe(
      map((r: StrictHttpResponse<UtenteOutput>) => r.body as UtenteOutput)
    );
  }

}
