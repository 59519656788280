import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/configuration.service';
import { Configurazione } from 'src/app/generated/mediazione/models';
import { ConfigurazioneService, UtenteDocumentiService } from 'src/app/generated/mediazione/services';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  nome: string;
  indirizzo: string;
  pec: string;
  mail: string;
  privacy: string;

  @Input() fullLayout: boolean;

  constructor(
    private configurazioneService: ConfigurazioneService,
    private utilService: UtilsService,
    private documentiService: UtenteDocumentiService
  ) { }

  ngOnInit(): void {
    const req = this.configurazioneService.findAllConfigurazione();
    req.subscribe(listaConf => {
      if (listaConf?.length > 0) {
        this.nome = listaConf.find(ll => ll.id === this.utilService.NOME_ODM)?.attribute;
        this.indirizzo = listaConf.find(ll => ll.id === this.utilService.INDIRIZZO_ODM)?.attribute;
        this.pec = listaConf.find(ll => ll.id === this.utilService.PEC_ODM)?.attribute;
        this.mail = listaConf.find(ll => ll.id === this.utilService.MAIL_ODM)?.attribute;
        this.privacy = listaConf.find(ll => ll.id === this.utilService.PRIVACY)?.attribute;
      }
    })
  }

  goToPrivacy() {
    this.documentiService.getDocumentiPrivacy().subscribe(res => {
      if (res) {
        this.utilService.download(res.objectKey, res.nomeFile);
      } else {
        if (this.privacy) {
          window.open(this.privacy);
        }
      }
    });
  }

}
