/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Pagination } from '../models/pagination';
import { Ruolo } from '../models/ruolo';
import { Sorting } from '../models/sorting';
import { Utente } from '../models/utente';
import { UtenteOutput } from '../models/utente-output';
import { UtenteSearch } from '../models/utente-search';


/**
 * User administration APIs
 */
@Injectable({
  providedIn: 'root',
})
export class UtentiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAll
   */
  static readonly FindAllPath = '/api/utenti';

  /**
   * generic search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(params?: {
    utente?: UtenteSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UtenteOutput>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiService.FindAllPath, 'get');
    if (params) {
      rb.query('utente', params.utente, {"explode":true});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UtenteOutput>;
      })
    );
  }

  /**
   * generic search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(params?: {
    utente?: UtenteSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<UtenteOutput> {

    return this.findAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<UtenteOutput>) => r.body as UtenteOutput)
    );
  }

  /**
   * Path part for operation saveNew
   */
  static readonly SaveNewPath = '/api/utenti';

  /**
   * insert new Utente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveNew()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveNew$Response(params: {
    body: Utente
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Utente>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiService.SaveNewPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Utente>;
      })
    );
  }

  /**
   * insert new Utente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveNew$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveNew(params: {
    body: Utente
  },
  context?: HttpContext

): Observable<Utente> {

    return this.saveNew$Response(params,context).pipe(
      map((r: StrictHttpResponse<Utente>) => r.body as Utente)
    );
  }

  /**
   * Path part for operation batchUpdateUserStatus
   */
  static readonly BatchUpdateUserStatusPath = '/api/utenti';

  /**
   * Batch user status update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `batchUpdateUserStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  batchUpdateUserStatus$Response(params: {
    enabled: boolean;
    userIds: Array<string>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiService.BatchUpdateUserStatusPath, 'delete');
    if (params) {
      rb.query('enabled', params.enabled, {"style":"form"});
      rb.query('userIds', params.userIds, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Batch user status update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `batchUpdateUserStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  batchUpdateUserStatus(params: {
    enabled: boolean;
    userIds: Array<string>;
  },
  context?: HttpContext

): Observable<Array<string>> {

    return this.batchUpdateUserStatus$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/api/utenti/{id}';

  /**
   * loads the Utente associated with the user id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: {

    /**
     * User Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Utente>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiService.GetUserPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Utente>;
      })
    );
  }

  /**
   * loads the Utente associated with the user id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: {

    /**
     * User Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Utente> {

    return this.getUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<Utente>) => r.body as Utente)
    );
  }

  /**
   * Path part for operation modifyUserRoles
   */
  static readonly ModifyUserRolesPath = '/api/utenti/{id}';

  /**
   * modify roles for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modifyUserRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  modifyUserRoles$Response(params: {

    /**
     * User Id
     */
    id: string;
    ruoli: Array<Ruolo>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Utente>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiService.ModifyUserRolesPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('ruoli', params.ruoli, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Utente>;
      })
    );
  }

  /**
   * modify roles for user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `modifyUserRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  modifyUserRoles(params: {

    /**
     * User Id
     */
    id: string;
    ruoli: Array<Ruolo>;
  },
  context?: HttpContext

): Observable<Utente> {

    return this.modifyUserRoles$Response(params,context).pipe(
      map((r: StrictHttpResponse<Utente>) => r.body as Utente)
    );
  }

  /**
   * Path part for operation updateUserStatus
   */
  static readonly UpdateUserStatusPath = '/api/utenti/{id}';

  /**
   * Update user status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateUserStatus$Response(params: {

    /**
     * User Id
     */
    id: string;
    enabled: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Utente>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiService.UpdateUserStatusPath, 'post');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('enabled', params.enabled, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Utente>;
      })
    );
  }

  /**
   * Update user status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateUserStatus(params: {

    /**
     * User Id
     */
    id: string;
    enabled: boolean;
  },
  context?: HttpContext

): Observable<Utente> {

    return this.updateUserStatus$Response(params,context).pipe(
      map((r: StrictHttpResponse<Utente>) => r.body as Utente)
    );
  }

  /**
   * Path part for operation deleteUser
   */
  static readonly DeleteUserPath = '/api/utenti/{id}';

  /**
   * deleteUser.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: {

    /**
     * User Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Utente>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiService.DeleteUserPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Utente>;
      })
    );
  }

  /**
   * deleteUser.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: {

    /**
     * User Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Utente> {

    return this.deleteUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<Utente>) => r.body as Utente)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/api/utenti/{id}';

  /**
   * update Utente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: {

    /**
     * User Id
     */
    id: string;
    body: Utente
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Utente>> {

    const rb = new RequestBuilder(this.rootUrl, UtentiService.UpdateUserPath, 'patch');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Utente>;
      })
    );
  }

  /**
   * update Utente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: {

    /**
     * User Id
     */
    id: string;
    body: Utente
  },
  context?: HttpContext

): Observable<Utente> {

    return this.updateUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<Utente>) => r.body as Utente)
    );
  }

}
