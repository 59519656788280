<div class="col-12">
  <h5>Anagrafica Parte</h5>
</div>
<div class="col-12 parte-form">
  <form nz-form [formGroup]="parteForm" *ngIf="!showLawyersForm && parteForm">
    <div class="row">
      <div class="col-12 py-3 mb-2 autocomplete-notification-container">
        <div class="row">
          <div class="col-6">
            <nz-form-item>
              <div class="row w-100">
                <div class="col-4 d-flex justify-content-end">
                  <nz-form-label nzFor="tipoPersona">Persona</nz-form-label>
                </div>
                <div class="col-8 d-flex align-items-center">
                  <nz-radio-group formControlName="tipoPersona" (ngModelChange)="onChangePersona($event)">
                    <label nz-radio nzValue="PersonaFisica">Persona Fisica</label>
                    <label nz-radio nzValue="PersonaGiuridica">Persona Giuridica</label>
                  </nz-radio-group>
                </div>
              </div>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <nz-form-item>
              <div class="row w-100">
                <div class="col-4 d-flex justify-content-end">
                  <nz-form-label nzRequired nzFor="codiceFiscale">CF</nz-form-label>
                </div>
                <div class="col-8">
                  <nz-form-control [nzErrorTip]="codiceFiscaleErrorTpl">
                    <input #codiceFiscale nz-input id="codiceFiscale" formControlName="codiceFiscale" placeholder="CF"
                      (input)="
                        codiceFiscale.value = codiceFiscale.value.toUpperCase();
                        onTypingCompileCodiceFiscaleOrPiva(
                          codiceFiscale.value,
                          'codiceFiscale'
                        )
                      " [nzAutocomplete]="codiceAutocomplete" />
                  </nz-form-control>
                  <nz-autocomplete #codiceAutocomplete>
                    <nz-auto-option *ngFor="let option of listAnagrafica" [nzValue]="option.codiceFiscale" (click)="
                        toggleFields(
                          ['codiceFiscale', 'denominazione', 'partitaIva'],
                          true,
                          'codiceFiscale',
                          option.id
                        )
                      ">{{ option.codiceFiscale }}</nz-auto-option>
                  </nz-autocomplete>
                  <ng-template #codiceFiscaleErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">Campo obbligatorio</ng-container>
                    <ng-container *ngIf="control.hasError('pattern') && parteForm.get('tipoPersona')?.value === 'PersonaFisica'">Inserisci un codice valido con 16 caratteri
                      alfanumerici</ng-container>
                      <ng-container *ngIf="control.hasError('pattern') && parteForm.get('tipoPersona')?.value !== 'PersonaFisica'">Inserisci un codice valido con 11 caratteri</ng-container>
                  </ng-template>
                </div>
              </div>
            </nz-form-item>
          </div>
          <div class="col-6">
            <span class="f-16">Inserire il Codice Fiscale, Denominazione o P.IVA per la
              compilazione automatica degli altri campi.</span>
          </div>
          <div class="col-6">
            <nz-form-item>
              <div class="row w-100">
                <div class="col-4 d-flex justify-content-end">
                  <nz-form-label nzRequired nzFor="denominazione">Denominazione</nz-form-label>
                </div>
                <div class="col-8">
                  <nz-form-control nzErrorTip="Campo obbligatorio">
                    <input #denominazione nz-input id="denominazione" formControlName="denominazione"
                      placeholder="Denominazione" (input)="
                        onTypingCompileDenominazione(denominazione.value)
                      " [nzAutocomplete]="denominazioneAutocomplete" />
                  </nz-form-control>
                  <nz-autocomplete #denominazioneAutocomplete>
                    <nz-auto-option *ngFor="let option of listAnagrafica" [nzValue]="option.denominazione" (click)="
                        toggleFields(
                          ['codiceFiscale', 'denominazione', 'partitaIva'],
                          true,
                          'denominazione',
                          option.id
                        )
                      ">{{ option.denominazione }}</nz-auto-option>
                  </nz-autocomplete>
                </div>
              </div>
            </nz-form-item>
          </div>
          <div class="col"></div>
          <div class="col-6">
            <nz-form-item>
              <div class="row w-100">
                <div class="col-4 d-flex justify-content-end">
                  <nz-form-label nzRequired nzFor="partitaIva">P.IVA</nz-form-label>
                </div>
                <div class="col-8">
                  <nz-form-control [nzErrorTip]="pivaErrorTpl">
                    <input #piva nz-input nz-input id="partitaIva" formControlName="partitaIva" placeholder="P.IVA"
                      (input)="
                        onTypingCompileCodiceFiscaleOrPiva(
                          piva.value,
                          'partitaIva'
                        )
                      " [nzAutocomplete]="pivaAutocomplete" />
                  </nz-form-control>
                </div>
                <ng-template #pivaErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">Campo obbligatorio</ng-container>
                  <ng-container *ngIf="control.hasError('pattern')">Il campo deve contenere 11 cifre</ng-container>
                  <ng-container *ngIf="control.hasError('maxLength')">Il campo deve contenere 11 cifre</ng-container>
                </ng-template>
                <nz-autocomplete #pivaAutocomplete>
                  <nz-auto-option *ngFor="let option of listAnagrafica" [nzValue]="option.partitaIva" (click)="
                      toggleFields(
                        ['codiceFiscale', 'denominazione', 'partitaIva'],
                        true,
                        'partitaIva',
                        option.id
                      )
                    ">{{ option.partitaIva }}</nz-auto-option>
                </nz-autocomplete>
              </div>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div class="col-6">
        <nz-form-item>
          <nz-input-group>
            <div class="row w-100">
              <div class="col-4 d-flex justify-content-end">
                <nz-form-label nzRequired nzFor="tipologia">Tipologia Ente</nz-form-label>
              </div>
              <div class="col-8">
                <nz-form-control nzErrorTip="Campo obbligatorio">
                  <nz-select formControlName="tipologiaClone" nzPlaceHolder="Seleziona Tipologia Ente"
                    (ngModelChange)="updateTipologia()" nzAllowClear>
                    <nz-option *ngFor="let tipologia of tipologiaEnteList" [nzLabel]="tipologia.descrizione"
                      [nzValue]="tipologia.id"></nz-option>
                  </nz-select>
                </nz-form-control>
              </div>
            </div>
          </nz-input-group>
        </nz-form-item>
        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="cittaResidenza">Città</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="cittaResidenza"
                  nzPlaceHolder="Comune di residenza">
                  <nz-option *ngFor="let option of comuniList" [nzLabel]="option.description"
                    [nzValue]="option.description"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>
        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="provinciaResidenza">Provincia</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear formControlName="provinciaResidenza"
                  nzPlaceHolder="Provincia residenza">
                  <nz-option *ngFor="let option of provinceList" [nzLabel]="option.description"
                    [nzValue]="option.code"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>
        <nz-form-item *ngIf="shouldBeAllDisabled">
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label>Utente</nz-form-label>
            </div>
            <div class="col-8 d-flex align-items-center">
              <span [class.fish-eye-success]="costituito" [class.fish-eye-error]="!costituito">&#9673;</span>
              <span class="costituito ml-2">{{
                costituito ? "Costituito" : "Non costituito"
                }}</span>
            </div>
          </div>
        </nz-form-item>
      </div>
      <div class="col-6">
        <nz-form-item>
          <nz-form-control [nzErrorTip]="capErrTemp">
            <div class="row w-100">
              <div class="col-4 d-flex justify-content-end">
                <nz-form-label nzFor="capResidenza">CAP</nz-form-label>
              </div>
              <div class="col-8">
                <input #capResidenza nz-input nz-input id="capResidenza" formControlName="capResidenza"
                  placeholder="CAP" type="number" (keypress)="keyPressNumbers($event, 'capResidenza')" />
              </div>
              <ng-template #capErrTemp let-control>
                <ng-container *ngIf="control.hasError('pattern')">Il campo deve contenere 5 cifre</ng-container>
              </ng-template>
            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzRequired nzFor="email">Email</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control [nzErrorTip]="emailErrorTpl">
                <input required nz-input id="email" formControlName="email" placeholder="Email" />
                <ng-template #emailErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">Inserisci l'email!</ng-container>
                  <ng-container *ngIf="control.hasError('email')">Inserisci un indirizzo mail
                    valido!</ng-container>
                </ng-template>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>
        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="pec">Pec</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control [nzErrorTip]="pecErrorTpl">
                <input nz-input id="pec" formControlName="pec" placeholder="Pec" />
                <ng-template #pecErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('email')">Inserisci un indirizzo mail
                    valido!</ng-container>
                </ng-template>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>
        <nz-form-item>
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="numeroTelefono">Cellulare</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <nz-input-group>
                  <input type="number" formControlName="numeroTelefono" id="numeroTelefono" placeholder="000 000 00 00"
                    nz-input />
                </nz-input-group>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>
        <nz-form-item *ngIf="!shouldBeAllDisabled">
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="documenti">Documenti</nz-form-label>
            </div>
            <div class="col-8">
              <app-uploader #uploader (cancelId)="handleDocumentDeletion($event)"
                (addId)="handleAddDocument($event)"></app-uploader>
            </div>
          </div>
        </nz-form-item>
        <nz-form-item *ngIf="!shouldBeAllDisabled">
          <div class="row w-100">
            <div class="col-4 d-flex justify-content-end">
              <nz-form-label nzFor="rappresentata">Parte rappresentata</nz-form-label>
            </div>
            <div class="col-8">
              <nz-form-control>
                <label nz-checkbox formControlName="rappresentata" id="rappresentata">
                </label>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>
      </div>
    </div>
  </form>
  <app-avvocato-form class="w-100" *ngIf="showLawyersForm" [isItFromParte]="true"
    [formData]="passFormData ? formData : null" [shouldBeAllDisabled]="shouldBeAllDisabled" [costituito]="costituito"
    [wasCodiceFiscaleDisabledBefore]="wasCodiceFiscaleDisabledBefore" [isAdesione]="isAdesione"
    [mediazione]="mediazione" (onChangePersonTypeToGiuridica)="changeForm($event)" #avvocatoForm></app-avvocato-form>
</div>