/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Formazione } from '../models/formazione';
import { FormazioneOutput } from '../models/formazione-output';
import { Pagination } from '../models/pagination';
import { Sorting } from '../models/sorting';

@Injectable({
  providedIn: 'root',
})
export class FormazioneService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserFormazione
   */
  static readonly GetUserFormazionePath = '/api/formazione';

  /**
   * get all formazione associated to the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFormazione()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFormazione$Response(params: {

    /**
     * user Id
     */
    id: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FormazioneOutput>> {

    const rb = new RequestBuilder(this.rootUrl, FormazioneService.GetUserFormazionePath, 'get');
    if (params) {
      rb.query('id', params.id, {"style":"form"});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormazioneOutput>;
      })
    );
  }

  /**
   * get all formazione associated to the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserFormazione$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFormazione(params: {

    /**
     * user Id
     */
    id: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<FormazioneOutput> {

    return this.getUserFormazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<FormazioneOutput>) => r.body as FormazioneOutput)
    );
  }

  /**
   * Path part for operation newFormazione
   */
  static readonly NewFormazionePath = '/api/formazione';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newFormazione()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newFormazione$Response(params?: {
    body?: Formazione
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Formazione>> {

    const rb = new RequestBuilder(this.rootUrl, FormazioneService.NewFormazionePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Formazione>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newFormazione$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newFormazione(params?: {
    body?: Formazione
  },
  context?: HttpContext

): Observable<Formazione> {

    return this.newFormazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<Formazione>) => r.body as Formazione)
    );
  }

  /**
   * Path part for operation deleteFormazione
   */
  static readonly DeleteFormazionePath = '/api/formazione/{id}';

  /**
   * delete formazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFormazione()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFormazione$Response(params: {

    /**
     * Formazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FormazioneService.DeleteFormazionePath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * delete formazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFormazione$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFormazione(params: {

    /**
     * Formazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteFormazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateFormazione
   */
  static readonly UpdateFormazionePath = '/api/formazione/{id}';

  /**
   * update Formazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFormazione()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormazione$Response(params: {

    /**
     * Formazione Id
     */
    id: string;
    body: Formazione
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Formazione>> {

    const rb = new RequestBuilder(this.rootUrl, FormazioneService.UpdateFormazionePath, 'patch');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Formazione>;
      })
    );
  }

  /**
   * update Formazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFormazione$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormazione(params: {

    /**
     * Formazione Id
     */
    id: string;
    body: Formazione
  },
  context?: HttpContext

): Observable<Formazione> {

    return this.updateFormazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<Formazione>) => r.body as Formazione)
    );
  }

}
