
import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { CacheService } from '../shared/services/cache.service';
import { Location } from '@angular/common';
import { Ruolo } from '../generated/mediazione/models';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected router: Router,
        protected keycloakAngular: KeycloakService,
        private cacheService: CacheService,
        private location: Location
    ) {
        super(router, keycloakAngular);
    }

    async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {


        if (!this.authenticated) {
            console.log(" win location ", window.location.origin);
            console.log(" url state ", state.url);
            await this.keycloakAngular.login({
                redirectUri: window.location.origin + '/#' + state.url,
            });
        }

        const tokenParsed: Keycloak.KeycloakTokenParsed | undefined = this.keycloakAngular.getKeycloakInstance().tokenParsed;
        this.cacheService.roles = tokenParsed?.groups.map(gg => gg.toUpperCase().replace('/', ''));
        this.cacheService.registrationPending = tokenParsed?.registrationPending === undefined && this.cacheService.roles.includes(Ruolo.Esterno) ? true : tokenParsed?.registrationPending;
        console.log(" registration ", this.cacheService.registrationPending);
        this.cacheService.codiceFiscale = tokenParsed?.codiceFiscale;
        this.cacheService.profileKC = await this.keycloakAngular.loadUserProfile();

        return this.authenticated;

    }
}
