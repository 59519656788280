import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Codebook } from 'src/app/codebooks/codebook.models';
import { CodebookServices } from 'src/app/codebooks/codebook.services';
import {
  Mediazione,
  TipoPersonaGiuridica,
} from 'src/app/generated/mediazione/models';
import { AvvocatoFormComponent } from '../avvocato-form/avvocato-form.component';
import { AnagraficaService } from 'src/app/generated/mediazione/services';
import { forkJoin } from 'rxjs';
import { UploaderComponent } from '../../uploader/uploader.component';
import { UtilsService } from 'src/app/shared/services/utils.service';

export interface ParteFormData {
  tipoPersona: string;
  denominazione: string;
  partitaIva: number;
  tipologia: string;
  numeroTelefono: string;
  email: string;
  pec: string;
  cittaResidenza: string;
  provinciaResidenza: string;
  capResidenza: string;
  codiceFiscale: string;
  indirizzoResidenza?: string;
  nome?: string;
  cognome?: string;
  rappresentata?: boolean;
}

@Component({
  selector: 'app-parte-form',
  templateUrl: './parte-form.component.html',
  styleUrls: ['./parte-form.component.scss'],
})
export class ParteFormComponent implements OnInit {
  parteForm: UntypedFormGroup;
  provinceList: Codebook[];
  statiList: Codebook[];
  comuniList: Codebook[];
  filteredProvinceOptions: Codebook[];
  tipologiaEnteList: TipoPersonaGiuridica[];
  listAnagrafica: any[] = [];
  showLawyersForm: boolean = true;
  passFormData: boolean = true;
  isCodiceFiscaleSelected: boolean = false;
  isCodiceFiscaleSelectedInAutocomplete: boolean = false;
  keyDecimal: boolean = false;
  idParte: string;

  @Input() wasCodiceFiscaleDisabledBefore: boolean;
  @Input() formData: ParteFormData;
  @Input() shouldBeAllDisabled: boolean;
  @Input() costituito: boolean;
  @Input() isAdesione: boolean;
  @Input() mediazione: Mediazione;

  @ViewChild('avvocatoForm') avvocatoForm: AvvocatoFormComponent;
  @ViewChild('uploader') uploader: UploaderComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private codebookServices: CodebookServices,
    private anagraficaService: AnagraficaService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.codebookServices.getTipologiaGiuridica().subscribe(res => {
      this.tipologiaEnteList = res;
      this.utilsService.getDropdownValues().subscribe(({ comuni, province, stati }) => {
        this.comuniList = comuni;
        this.provinceList = province;
        this.statiList = stati;
        this.checkIfTheUserWantsToOpenAnExistingForm();
      });
    });
  }

  checkIfTheUserWantsToOpenAnExistingForm() {
    console.log(' check ');
    if (this.formData) {
      this.initForm(this.formData?.tipoPersona);
      this.onChangePersona(this.formData.tipoPersona);
      if (this.formData.tipoPersona === 'PersonaFisica') {
        this.showLawyersForm = true;
      } else {
        this.passFormData = false;
        this.parteForm.patchValue({ ...this.formData });

        const tipologia: any = this.formData.tipologia;

        this.parteForm.get('tipologiaClone').setValue(tipologia.id);

        if (this.shouldBeAllDisabled) {
          this.toggleFields(null, true);
        }

        if (this.wasCodiceFiscaleDisabledBefore) {
          this.toggleFields(['codiceFiscale'], true);
        }
      }
    } else {
      this.initForm();
    }
  }


  initForm(persona?: string): void {
    console.log(' INIT FORM  ', persona);
    this.parteForm = this.fb.group({
      tipoPersona: [
        { value: persona ? persona : 'PersonaFisica', disabled: false },
      ],
      denominazione: [{ value: null, disabled: false }, Validators.required],
      partitaIva: [
        { value: null, disabled: false },
        [Validators.pattern('^[0-9]{11}$'), Validators.required],
      ],
      tipologiaClone: [{ value: null, disabled: false }, Validators.required],
      tipologia: [{ value: null, disabled: false }, Validators.required],
      numeroTelefono: [{ value: null, disabled: false }],
      email: [{ value: null, disabled: false }, [Validators.email, Validators.required]],
      pec: [{ value: null, disabled: false }, [Validators.email]],
      phoneNumberPrefix: ['+39'],
      cittaResidenza: [{ value: null, disabled: false }],
      provinciaResidenza: [{ value: null, disabled: false }],
      capResidenza: [
        { value: null, disabled: false },
        Validators.pattern('^[0-9]{5}$'),
      ],
      codiceFiscale: [
        { value: null, disabled: false },
        [
          Validators.pattern(
            /^[A-Z]{6}[LMNPQRSTUV0-9]{2}[ABCDEHLMPRST][LMNPQRSTUV0-9]{2}[A-Z][LMNPQRSTUV0-9]{3}[A-Z]|[0-9]{11}$/i
          ),
          Validators.required,
        ],
      ],
      documenti: [null],
      rappresentata: [false],
    });
  }

  onChangePersona(decision: string) {
    console.log(' change persona ');
    if (decision === 'PersonaFisica') {
      this.parteForm.reset();
      this.showLawyersForm = true;
    } else {
      this.showLawyersForm = false;
    }

    /*
    if (this.isAdesione) {
      setTimeout(() => {
        if (this.idParte) {
          this.disableFieldsForAderente(['codiceFiscale', 'partitaIva', 'denominazione']);
        } else {
          this.disableFieldsForAderente(['codiceFiscale', 'partitaIva', 'denominazione', 'tipoPersona']);
        }
        this.avvocatoForm?.disableFieldsForAderente();
      }, 800);
    }
    */
  }

  changeForm(decision: boolean) {
    console.log(' change form ');
    if (decision) {
      this.showLawyersForm = false;
      this.parteForm.get('tipoPersona').setValue('PersonaGiuridica');
    }
  }

  onTypingCompileCodiceFiscaleOrPiva(cfOrPiva: string, field: string) {
    this.toggleFields(['denominazione', 'codiceFiscale', 'partitaIva'], false);
    console.log(' typing part ');
    /*if (this.isAdesione) {
      if (cfOrPiva !== '') {
        const partiAny: any[] = this.mediazione.parti.filter( pp => pp.tipo !== 'ESTROMESSO');;
        const pgs = partiAny?.filter(pp => pp.datiAnagrafici.tipoPersona === 'PersonaGiuridica');
        const filtered = field === 'codiceFiscale' ? pgs?.filter(pp => pp.datiAnagrafici.codiceFiscale?.toLowerCase()?.includes(cfOrPiva.toLowerCase())) : pgs?.filter(pp => pp.datiAnagrafici.partitaIva?.toLowerCase()?.includes(cfOrPiva.toLowerCase()));
        const list: any[] = filtered?.filter(pp => (pp.tipo === 'RESISTENTE' || pp.tipo === 'CHIAMATO') && !pp.costituito);
        this.listAnagrafica = list.map(ll => { return { ...ll.datiAnagrafici, id: ll.id } });
      }
    } else {*/
    if (cfOrPiva !== '') {
      this.anagraficaService
        .lookupAnagrafica({
          tipoPersona: this.parteForm.get('tipoPersona').value,
          filter: {
            cfOrPiva,
          },
        })
        .subscribe((res) => {
          this.listAnagrafica = res.list;
        });
    }
    //}

    if (field === 'codiceFiscale') {
      if (!this.parteForm.get('codiceFiscale').valid) {
        this.toggleFields(
          ['denominazione', 'codiceFiscale', 'partitaIva'],
          false
        );
      }
    }
  }

  onTypingCompileDenominazione(denominazione: string) {
    this.toggleFields(['denominazione', 'codiceFiscale', 'partitaIva'], false);
    /*if (this.isAdesione) {
      if (denominazione !== '') {
        const partiAny: any[] = this.mediazione.parti.filter( pp => pp.tipo !== 'ESTROMESSO');
        const pgs = partiAny?.filter(pp => pp.datiAnagrafici.tipoPersona === 'PersonaGiuridica');
        const filtered = pgs?.filter(pp => pp.datiAnagrafici.denominazione?.toLowerCase()?.includes(denominazione?.toLowerCase()));
        const list: any[] = filtered?.filter(pp => (pp.tipo === 'RESISTENTE' || pp.tipo === 'CHIAMATO') && !pp.costituito);
        this.listAnagrafica = list.map(ll => { return { ...ll.datiAnagrafici, id: ll.id } });
      }
    } else {*/
    if (denominazione !== '') {
      this.anagraficaService
        .lookupAnagrafica({
          tipoPersona: this.parteForm.get('tipoPersona').value,
          filter: {
            cognomeOrDenominazione: denominazione,
          },
        })
        .subscribe((res) => {
          this.listAnagrafica = res.list;
        });
    }
    // }
  }

  updateTipologia() {
    console.log(' tipologia ');
    const selectedTipologia: { id: string; descrizione: string } = {
      id: this.parteForm.get('tipologiaClone').value,
      descrizione: null,
    };

    if (selectedTipologia.id) {
      selectedTipologia.descrizione = this.tipologiaEnteList.find(
        (element: TipoPersonaGiuridica) => element.id === selectedTipologia.id
      ).descrizione;
    }

    this.parteForm.get('tipologia').setValue(selectedTipologia);
  }

  toggleFields(
    exeption: string[],
    shouldDisable: boolean,
    field: string = null,
    id?: string
  ) {
    console.log(' toggle ');
    if (field && id) {
      let val: any;

      if (this.isAdesione) {
        this.idParte = id;
      }
      const selected = this.listAnagrafica.filter((ff) => ff.id === id);

      if (selected && selected[0]) {
        if (selected[0].tipologia) {
          this.parteForm
            .get('tipologiaClone')
            .setValue(selected[0].tipologia.id);
        }
        this.parteForm.patchValue(selected[0], {
          emitEvent: false,
          onlySelf: true,
        });
      }

      if (field !== 'codiceFiscale') {
        this.parteForm.get('codiceFiscale').updateValueAndValidity();
      }

      this.listAnagrafica = [];
    }

    if (shouldDisable) {
      this.isCodiceFiscaleSelected = true;
    } else {
      this.isCodiceFiscaleSelected = false;
    }

    Object.keys(this.parteForm.controls).forEach((key: string) => {
      if (!exeption?.includes(key)) {
        if (shouldDisable) {
          if (key !== 'rappresentata' && key !== 'documenti') {
            this.parteForm.get(key).disable();
          }
        } else {
          if (this.isAdesione) {
            if (
              key === 'denominazione' ||
              key === 'tipoPersona' ||
              key === 'partitaIva'
            ) {
              this.parteForm.get(key).enable();
            }
          } else {
            if (key !== 'documenti' && key !== 'rappresentata') {
              this.parteForm.get(key).enable();
            }
          }

          if (key === 'documenti') {
            if (this.parteForm.get('documenti').value) {
              for (const iterator of this.parteForm.get('documenti').value) {
                this.uploader.handleRemove({
                  name: iterator.nomeFile,
                  uid: iterator.objectKey,
                });
              }
              this.uploader.formdata.delete('files');
              this.uploader.files = [];
              this.parteForm.get('documenti').setValue(null);
            }
          } else {
            if (key !== 'tipoPersona' && key !== 'rappresentata') {
              this.parteForm.get(key).reset();
            }
          }
        }
      }
    });
  }

  public disableFieldsForAderente(fields: string[]) {
    Object.keys(this.parteForm.controls).forEach((key: string) => {
      if (!fields.includes(key)) {
        this.parteForm.get(key).disable();
      } else {
        this.parteForm.get(key).enable();
      }
    });
  }

  keyPressNumbers(event, field: string) {
    var perValue = this.parteForm.get(field).value;

    var charCode = event.which ? event.which : event.keyCode;

    if (
      perValue === null ||
      perValue.toString().indexOf('.') == -1 ||
      Number.isInteger(perValue)
    ) {
      this.keyDecimal = false;
    }

    if (charCode < 45 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      if (charCode == 46 && !this.keyDecimal) {
        this.keyDecimal = true;
      } else if ((charCode == 46 && this.keyDecimal) || charCode == 47) {
        event.preventDefault();
        return false;
      }
      return true;
    }
  }

  handleDocumentDeletion(uid: string) {
    const filteredDocuments = this.parteForm
      .get('documenti')
      .value.filter((ff) => ff.objectKey !== uid);
    this.parteForm.get('documenti').setValue(filteredDocuments);
  }

  handleAddDocument(item: { idTemp: string; uidNz: string }) {
    if (!this.parteForm.get('documenti').value) {
      this.parteForm.get('documenti').setValue([]);
    }

    const filteredDocuments = this.parteForm
      .get('documenti')
      .value.filter((ff) => ff.nomeFile !== item.idTemp);

    this.parteForm.get('documenti').setValue(filteredDocuments);

    this.parteForm
      .get('documenti')
      .setValue([
        ...this.parteForm.get('documenti').value,
        { nomeFile: item.idTemp, objectKey: item.uidNz },
      ]);
  }
}
