import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AvvocatoFormComponent,
  AvvocatoFormData,
} from './avvocato-form/avvocato-form.component';
import {
  ParteFormComponent,
  ParteFormData,
} from './parte-form/parte-form.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Mediazione } from 'src/app/generated/mediazione/models';

export interface Panel {
  active: boolean;
  name: string;
  icon?: string;
  isParte: boolean;
  formData?: ParteFormData | AvvocatoFormData;
  wasCodiceFiscaleDisabledBefore?: boolean;
  canBeDeleted: boolean;
  costituito?: boolean;
  isANewLawyerBeingAdded?: boolean;
}

@Component({
  selector: 'app-anagrafica-parte',
  templateUrl: './anagrafica-parte.component.html',
  styleUrls: ['./anagrafica-parte.component.scss'],
})
export class AnagraficaParteComponent implements OnChanges, OnDestroy {
  panels: Panel[] = [
    {
      active: true,
      name: 'PARTE',
      isParte: true,
      canBeDeleted: false,
    },
  ];

  @Input() parentIndex: number;
  @Input() isModalClosing: boolean;
  @Input() isAdesione: boolean;
  @Input() panelsFromParent: Panel[];
  @Input() origin: 'PROPONENTE' | 'RESISTENTE' | 'INTERVENUTO' | 'CHIAMATO';
  @Input() shouldBeAllDisabled: boolean;
  @Input() mediazione: Mediazione;

  @Output() dataEvent: EventEmitter<{ data: Panel[]; index: number }> =
    new EventEmitter<{ data: Panel[]; index: number }>();

  @ViewChild('parteForm') parteForm: ParteFormComponent;
  @ViewChildren('avvocatoForm') avvocatoForm: QueryList<AvvocatoFormComponent>;

  constructor(private modal: NzModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(" test *** ");
    if (
      this.origin === 'PROPONENTE' &&
      this.parentIndex === null &&
      !this.isModalClosing
    ) {
    }
    if (this.parentIndex !== null && !this.isModalClosing) {
      this.panels = [...this.panelsFromParent];
    }
  }

  removeLawyer(event: MouseEvent, index: number): void {
    event.stopPropagation();
    this.modal.confirm({
      nzTitle: 'Vuoi continuare ?',
      nzContent: 'continuando perderai i dati di questo avvocato',
      nzOnOk: () => {
        this.panels.splice(index, 1);
        for (let index = 0; index < this.panels.length; index++) {
          if (index === 0) {
            this.panels[index].active = true;
          } else {
            this.panels[index].active = false;
          }
        }
      },
    });
  }

  getParteFormData() {
    const form: UntypedFormGroup = this.parteForm.parteForm;
    if (form.get('tipoPersona').value !== 'PersonaGiuridica') {
      this.panels[0].formData = this.parteForm.avvocatoForm.form.getRawValue();
      this.panels[0].wasCodiceFiscaleDisabledBefore =
        this.parteForm.avvocatoForm.isCodiceFiscaleSelected;
      console.log("  *********** getParteFormData ")
    } else {
      this.panels[0].formData = form.getRawValue();
      this.panels[0].wasCodiceFiscaleDisabledBefore =
        this.parteForm.isCodiceFiscaleSelected;
      console.log("  *********** getParteFormData  else")
    }
  }

  getLawyerData(): void {
    if (this.panels.length > 1) {
      this.avvocatoForm.forEach(
        (item: AvvocatoFormComponent, index: number) => {
          this.panels[index + 1].formData = item.form.getRawValue();
          this.panels[index + 1].wasCodiceFiscaleDisabledBefore =
            item.isCodiceFiscaleSelected;
        }
      );
    }
  }

  emitData() {
    this.dataEvent.emit({ data: this.panels, index: this.parentIndex });
  }

  ngOnDestroy(): void {
    if (this.isModalClosing) {
      this.getParteFormData();
      this.getLawyerData();
      this.emitData();
    }
  }
}
