import { Pipe, PipeTransform } from '@angular/core';
import { SpesaCheck } from 'src/app/main-layout/reserved-area/configurazioni/spese/spese.component';

@Pipe({ name: 'numberSort' })
export class NumberSortPipe implements PipeTransform {
  public transform(array: SpesaCheck[]): SpesaCheck[] {
    return !array?.length ? array : array.sort((a: SpesaCheck, b: SpesaCheck) => {
      const limiteA = a.limiteInferiore < 0 ? Infinity : a.limiteInferiore;
      const limiteB = b.limiteInferiore < 0 ? Infinity : b.limiteInferiore;
      return limiteA - limiteB;
    })
  }
}
