/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Anagrafica } from '../models/anagrafica';
import { AnagraficaOutput } from '../models/anagrafica-output';
import { AnagraficaSearch } from '../models/anagrafica-search';
import { Pagination } from '../models/pagination';
import { Sorting } from '../models/sorting';
import { TipoPersonaGiuridica } from '../models/tipo-persona-giuridica';

@Injectable({
  providedIn: 'root',
})
export class AnagraficaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAllAnagrafica
   */
  static readonly FindAllAnagraficaPath = '/api/anagrafica';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllAnagrafica()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAnagrafica$Response(params?: {
    search?: AnagraficaSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnagraficaOutput>> {

    const rb = new RequestBuilder(this.rootUrl, AnagraficaService.FindAllAnagraficaPath, 'get');
    if (params) {
      rb.query('search', params.search, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnagraficaOutput>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllAnagrafica$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAnagrafica(params?: {
    search?: AnagraficaSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<AnagraficaOutput> {

    return this.findAllAnagrafica$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnagraficaOutput>) => r.body as AnagraficaOutput)
    );
  }

  /**
   * Path part for operation newAnagrafica
   */
  static readonly NewAnagraficaPath = '/api/anagrafica';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newAnagrafica()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newAnagrafica$Response(params?: {
    body?: Anagrafica
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Anagrafica>> {

    const rb = new RequestBuilder(this.rootUrl, AnagraficaService.NewAnagraficaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Anagrafica>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newAnagrafica$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newAnagrafica(params?: {
    body?: Anagrafica
  },
  context?: HttpContext

): Observable<Anagrafica> {

    return this.newAnagrafica$Response(params,context).pipe(
      map((r: StrictHttpResponse<Anagrafica>) => r.body as Anagrafica)
    );
  }

  /**
   * Path part for operation lookupAnagrafica
   */
  static readonly LookupAnagraficaPath = '/api/anagrafica/lookup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupAnagrafica()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupAnagrafica$Response(params: {
    sort?: null | Sorting;
    page?: null | Pagination;

    /**
     * tipoPersona for Anagrafica
     */
    tipoPersona: 'PersonaFisica' | 'PersonaGiuridica';
    filter: {

/**
 * codice fiscale or partita iva lookup field
 */
'cfOrPiva'?: string;

/**
 * cognome or denominazione lookup field
 */
'cognomeOrDenominazione'?: string;
};
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AnagraficaOutput>> {

    const rb = new RequestBuilder(this.rootUrl, AnagraficaService.LookupAnagraficaPath, 'get');
    if (params) {
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('tipoPersona', params.tipoPersona, {"style":"simple"});
      rb.query('filter', params.filter, {"style":"form","explode":true});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AnagraficaOutput>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupAnagrafica$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupAnagrafica(params: {
    sort?: null | Sorting;
    page?: null | Pagination;

    /**
     * tipoPersona for Anagrafica
     */
    tipoPersona: 'PersonaFisica' | 'PersonaGiuridica';
    filter: {

/**
 * codice fiscale or partita iva lookup field
 */
'cfOrPiva'?: string;

/**
 * cognome or denominazione lookup field
 */
'cognomeOrDenominazione'?: string;
};
  },
  context?: HttpContext

): Observable<AnagraficaOutput> {

    return this.lookupAnagrafica$Response(params,context).pipe(
      map((r: StrictHttpResponse<AnagraficaOutput>) => r.body as AnagraficaOutput)
    );
  }

  /**
   * Path part for operation lookupTipoPg
   */
  static readonly LookupTipoPgPath = '/api/anagrafica/tipo_pg';

  /**
   * load the list of valid types for a Persona Giuridica.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupTipoPg()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupTipoPg$Response(params?: {
    sort?: null | Sorting;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TipoPersonaGiuridica>>> {

    const rb = new RequestBuilder(this.rootUrl, AnagraficaService.LookupTipoPgPath, 'get');
    if (params) {
      rb.query('sort', params.sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TipoPersonaGiuridica>>;
      })
    );
  }

  /**
   * load the list of valid types for a Persona Giuridica.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupTipoPg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupTipoPg(params?: {
    sort?: null | Sorting;
  },
  context?: HttpContext

): Observable<Array<TipoPersonaGiuridica>> {

    return this.lookupTipoPg$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TipoPersonaGiuridica>>) => r.body as Array<TipoPersonaGiuridica>)
    );
  }

}
