import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './authentication/auth.guard';
import { RegistrationGuard } from './authentication/registration.guard';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'authentication',
        pathMatch: 'full',
    },
    {
        path: 'authentication',
        loadChildren: () => import('../app/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'home',
        loadChildren: () => import('../app/main-layout/main-layout.module').then(m => m.MainLayoutModule),
        canActivate: [AuthGuard]
    } 
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}