import { Component } from '@angular/core';

@Component({
  selector: 'app-dettaglio-notifica',
  templateUrl: './dettaglio-notifica.component.html',
  styleUrls: ['./dettaglio-notifica.component.scss'],
})
export class DettaglioNotificaComponent {
  dataSource: {
    date: string;
    time: string;
    status: 'WARNING' | 'WAITING' | 'DONE';
    details: string;
    ruoli: Array<'SEGRETARIO' | 'AMMINISTRATORE' | 'MEDIATORE' | 'parte'>;
    file: boolean;
  }[] = [
    {
      date: '20.02.2023',
      time: '17:19',
      status: 'WARNING',
      details:
        'Il Mediatore ha fissato in data 23.02.2023 un nuovo incontro tra le parti',
      ruoli: ['MEDIATORE'],
      file: false,
    },
  ];

  loading = false;
  pageSize = 5;
  pageIndex = 1;
  total = 0;
  listPages = [2, 5, 10, 20, 30];

  columnList: {
    code: string;
    sort: boolean;
    property: string;
    width: string;
  }[] = [
    { code: 'Data', property: 'date', sort: true, width: '160px' },
    { code: 'Ora', property: 'time', sort: true, width: '120px' },
    { code: 'Stato', property: 'status', sort: false, width: '120px' },
    { code: 'Dettaglio', property: 'details', sort: false, width: null },
    { code: 'Ruolo', property: 'ruoli', sort: false, width: '120px' },
    { code: 'File', property: 'file', sort: false, width: '80px' },
  ];

  constructor() {}

  onQueryParamsChange(params): void {}
}
