/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NotificationOutput } from '../models/notification-output';
import { Pagination } from '../models/pagination';
import { Sorting } from '../models/sorting';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getNotifications
   */
  static readonly GetNotificationsPath = '/api/notification';

  /**
   * Get list of notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications$Response(params?: {
    anagraphica_id?: string;
    mediazione_id?: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<NotificationOutput>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.GetNotificationsPath, 'get');
    if (params) {
      rb.query('anagraphica_id', params.anagraphica_id, {});
      rb.query('mediazione_id', params.mediazione_id, {});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NotificationOutput>;
      })
    );
  }

  /**
   * Get list of notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications(params?: {
    anagraphica_id?: string;
    mediazione_id?: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<NotificationOutput> {

    return this.getNotifications$Response(params,context).pipe(
      map((r: StrictHttpResponse<NotificationOutput>) => r.body as NotificationOutput)
    );
  }

  /**
   * Path part for operation countNotifications
   */
  static readonly CountNotificationsPath = '/api/notification/count';

  /**
   * count notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  countNotifications$Response(params: {
    anagraphica_id?: string;
    mediazione_id?: string;
    status: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.CountNotificationsPath, 'get');
    if (params) {
      rb.query('anagraphica_id', params.anagraphica_id, {});
      rb.query('mediazione_id', params.mediazione_id, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * count notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countNotifications(params: {
    anagraphica_id?: string;
    mediazione_id?: string;
    status: string;
  },
  context?: HttpContext

): Observable<number> {

    return this.countNotifications$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation updateNotificationStatus
   */
  static readonly UpdateNotificationStatusPath = '/api/notification/{id}';

  /**
   * Change notification status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotificationStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateNotificationStatus$Response(params: {
    id: string;
    status: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateNotificationStatusPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change notification status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNotificationStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateNotificationStatus(params: {
    id: string;
    status: boolean;
  },
  context?: HttpContext

): Observable<void> {

    return this.updateNotificationStatus$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateAnagraficaNotificationsStatus
   */
  static readonly UpdateAnagraficaNotificationsStatusPath = '/api/notification/anagrafica/{anagraficaId}/{status}';

  /**
   * Mark or change status for all notifications beloging to {anagraficaId}.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnagraficaNotificationsStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAnagraficaNotificationsStatus$Response(params: {
    anagraficaId: string;
    status: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateAnagraficaNotificationsStatusPath, 'patch');
    if (params) {
      rb.path('anagraficaId', params.anagraficaId, {});
      rb.path('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Mark or change status for all notifications beloging to {anagraficaId}.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnagraficaNotificationsStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAnagraficaNotificationsStatus(params: {
    anagraficaId: string;
    status: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.updateAnagraficaNotificationsStatus$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
