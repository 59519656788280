/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Evento } from '../models/evento';
import { IstanzaProsecuzione } from '../models/istanza-prosecuzione';
import { MateriaMediazione } from '../models/materia-mediazione';
import { Mediazione } from '../models/mediazione';
import { MediazioneOutput } from '../models/mediazione-output';
import { MediazioneParziale } from '../models/mediazione-parziale';
import { MediazioneSearch } from '../models/mediazione-search';
import { Nota } from '../models/nota';
import { Pagination } from '../models/pagination';
import { RichiestaDocumentale } from '../models/richiesta-documentale';
import { Sorting } from '../models/sorting';

@Injectable({
  providedIn: 'root',
})
export class MediazioneUtentiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRichiestaDocumentaleEsterniByMediazione
   */
  static readonly GetRichiestaDocumentaleEsterniByMediazionePath = '/api/richiesteDocumentali/{id}';

  /**
   * loads the richieste documentali associated to the user with the mediazione id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRichiestaDocumentaleEsterniByMediazione()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRichiestaDocumentaleEsterniByMediazione$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RichiestaDocumentale>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.GetRichiestaDocumentaleEsterniByMediazionePath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RichiestaDocumentale>>;
      })
    );
  }

  /**
   * loads the richieste documentali associated to the user with the mediazione id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRichiestaDocumentaleEsterniByMediazione$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRichiestaDocumentaleEsterniByMediazione(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Array<RichiestaDocumentale>> {

    return this.getRichiestaDocumentaleEsterniByMediazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RichiestaDocumentale>>) => r.body as Array<RichiestaDocumentale>)
    );
  }

  /**
   * Path part for operation lookupMateriaUtenti
   */
  static readonly LookupMateriaUtentiPath = '/api/mediazione/materia';

  /**
   * load the list of Materias for a Mediazione Obbligatoria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupMateriaUtenti()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupMateriaUtenti$Response(params?: {
    sort?: null | Sorting;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MateriaMediazione>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.LookupMateriaUtentiPath, 'get');
    if (params) {
      rb.query('sort', params.sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MateriaMediazione>>;
      })
    );
  }

  /**
   * load the list of Materias for a Mediazione Obbligatoria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupMateriaUtenti$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupMateriaUtenti(params?: {
    sort?: null | Sorting;
  },
  context?: HttpContext

): Observable<Array<MateriaMediazione>> {

    return this.lookupMateriaUtenti$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MateriaMediazione>>) => r.body as Array<MateriaMediazione>)
    );
  }

  /**
   * Path part for operation findAllMediazioneUtenti
   */
  static readonly FindAllMediazioneUtentiPath = '/api/mediazione';

  /**
   * find all mediazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllMediazioneUtenti()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllMediazioneUtenti$Response(params?: {
    search?: MediazioneSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MediazioneOutput>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.FindAllMediazioneUtentiPath, 'get');
    if (params) {
      rb.query('search', params.search, {"explode":true});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediazioneOutput>;
      })
    );
  }

  /**
   * find all mediazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllMediazioneUtenti$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllMediazioneUtenti(params?: {
    search?: MediazioneSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<MediazioneOutput> {

    return this.findAllMediazioneUtenti$Response(params,context).pipe(
      map((r: StrictHttpResponse<MediazioneOutput>) => r.body as MediazioneOutput)
    );
  }

  /**
   * Path part for operation getMediazioneUtenti
   */
  static readonly GetMediazioneUtentiPath = '/api/mediazione/{id}';

  /**
   * loads the Mediazione associated with the mediazione id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediazioneUtenti()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediazioneUtenti$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mediazione>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.GetMediazioneUtentiPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mediazione>;
      })
    );
  }

  /**
   * loads the Mediazione associated with the mediazione id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediazioneUtenti$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediazioneUtenti(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Mediazione> {

    return this.getMediazioneUtenti$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mediazione>) => r.body as Mediazione)
    );
  }

  /**
   * Path part for operation getStoricoMediazioneUtenti
   */
  static readonly GetStoricoMediazioneUtentiPath = '/api/mediazione/{id}/storico';

  /**
   * load the storico of specific Mediazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStoricoMediazioneUtenti()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStoricoMediazioneUtenti$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Evento>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.GetStoricoMediazioneUtentiPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Evento>>;
      })
    );
  }

  /**
   * load the storico of specific Mediazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStoricoMediazioneUtenti$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStoricoMediazioneUtenti(params: {

    /**
     * Mediazione Id
     */
    id: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<Array<Evento>> {

    return this.getStoricoMediazioneUtenti$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Evento>>) => r.body as Array<Evento>)
    );
  }

  /**
   * Path part for operation getIstanzeMediazioneUtenti
   */
  static readonly GetIstanzeMediazioneUtentiPath = '/api/mediazione/{id}/istanze';

  /**
   * load the istanze of specific Mediazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIstanzeMediazioneUtenti()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIstanzeMediazioneUtenti$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<IstanzaProsecuzione>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.GetIstanzeMediazioneUtentiPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IstanzaProsecuzione>>;
      })
    );
  }

  /**
   * load the istanze of specific Mediazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIstanzeMediazioneUtenti$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIstanzeMediazioneUtenti(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Array<IstanzaProsecuzione>> {

    return this.getIstanzeMediazioneUtenti$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<IstanzaProsecuzione>>) => r.body as Array<IstanzaProsecuzione>)
    );
  }

  /**
   * Path part for operation getMediazioneByProtocollo
   */
  static readonly GetMediazioneByProtocolloPath = '/api/mediazione/nProtocollo';

  /**
   * loads the Mediazione associated with the protocollo id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediazioneByProtocollo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediazioneByProtocollo$Response(params: {

    /**
     * numero Protocollo
     */
    nProtocollo: string;

    /**
     * anno Protocollo
     */
    annoProtocollo: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MediazioneParziale>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.GetMediazioneByProtocolloPath, 'get');
    if (params) {
      rb.query('nProtocollo', params.nProtocollo, {"style":"simple"});
      rb.query('annoProtocollo', params.annoProtocollo, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MediazioneParziale>>;
      })
    );
  }

  /**
   * loads the Mediazione associated with the protocollo id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediazioneByProtocollo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediazioneByProtocollo(params: {

    /**
     * numero Protocollo
     */
    nProtocollo: string;

    /**
     * anno Protocollo
     */
    annoProtocollo: string;
  },
  context?: HttpContext

): Observable<Array<MediazioneParziale>> {

    return this.getMediazioneByProtocollo$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MediazioneParziale>>) => r.body as Array<MediazioneParziale>)
    );
  }

  /**
   * Path part for operation addNota
   */
  static readonly AddNotaPath = '/api/note';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNota()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNota$Response(params?: {
    body?: Nota
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Nota>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.AddNotaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Nota>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addNota$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNota(params?: {
    body?: Nota
  },
  context?: HttpContext

): Observable<Nota> {

    return this.addNota$Response(params,context).pipe(
      map((r: StrictHttpResponse<Nota>) => r.body as Nota)
    );
  }

  /**
   * Path part for operation getNotaByMediazione
   */
  static readonly GetNotaByMediazionePath = '/api/note/{id}';

  /**
   * loads the nota associated to the user with the mediazione id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotaByMediazione()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotaByMediazione$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Nota>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.GetNotaByMediazionePath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Nota>;
      })
    );
  }

  /**
   * loads the nota associated to the user with the mediazione id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotaByMediazione$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotaByMediazione(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Nota> {

    return this.getNotaByMediazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<Nota>) => r.body as Nota)
    );
  }

  /**
   * Path part for operation updateNota
   */
  static readonly UpdateNotaPath = '/api/note/{id}';

  /**
   * update nota.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNota()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNota$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
    body: Nota
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Nota>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneUtentiService.UpdateNotaPath, 'patch');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Nota>;
      })
    );
  }

  /**
   * update nota.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNota$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNota(params: {

    /**
     * Mediazione Id
     */
    id: string;
    body: Nota
  },
  context?: HttpContext

): Observable<Nota> {

    return this.updateNota$Response(params,context).pipe(
      map((r: StrictHttpResponse<Nota>) => r.body as Nota)
    );
  }

}
