<div class="allNotifications">
  <nz-table #notificationsDataTable [nzData]="dataSource" [nzFrontPagination]="false" [nzLoading]="loading"
    [nzTotal]="total" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex" nzTableLayout="auto" nzShowSizeChanger
    [nzPageSizeOptions]="listPages" [nzScroll]="{ x: '100we' }" (nzQueryParams)="onQueryParamsChange($event)">
    <thead>
      <tr>
        <th class="px-2" [nzColumnKey]="column.property" [nzShowSort]="column.sort" [nzSortFn]="column.sort"
          [nzWidth]="column.width" *ngFor="let column of columnList">
          <ng-container *ngIf="column.code === 'Status'">
        <th [(nzChecked)]="allChecked" [nzDisabled]="allChecked" (nzCheckedChange)="checkAll($event)"></th>
        Segna tutte come viste
        </ng-container>
        <ng-container *ngIf="column.code !== 'Status'">
          <ng-container *ngIf="column.code !== 'Dettaglio'">
            {{ column.code }}
          </ng-container>
          <ng-container *ngIf="column.code === 'Dettaglio'">
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ column.code }}</span>
              <nz-input-group [nzPrefix]="prefixTemplate" [nzSuffix]="inputClearTpl" class="ml-5">
                <input nz-input placeholder="Cerca" [(ngModel)]="searchValue" (keyup)="filterData()" />
              </nz-input-group>
            </div>
          </ng-container>
        </ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of notificationsDataTable.data">
        <td class="px-2" *ngFor="let column of columnList" [class.light-blue-background]="!data.status">
          <ng-container [ngSwitch]="column['property']">
            <ng-container *ngSwitchCase="'status'">
        <td [(nzChecked)]="data.status" [nzDisabled]="data.status"
          (nzCheckedChange)="updateNotification(data)"></td>
        </ng-container>
        <ng-container *ngSwitchCase="'creationDate'">
          {{ data[column["property"]] | date : "dd.MM.yyyy" }}
        </ng-container>
        <ng-container *ngSwitchCase="'description'">
          <span>{{ data[column["property"]] }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="'mediazione'">
          <button nz-button nzType="primary" class="primary-button"
            (click)="goToTheSpecifiedMediazione(data.mediazione.id)">
            Mediazione
          </button>
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ data[column["property"]] }}
        </ng-container>
        </ng-container>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <ng-template #prefixTemplate><span nz-icon nzType="search" nzTheme="outline"></span></ng-template>
  <ng-template #inputClearTpl>
    <span nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="searchValue"
      (click)="makeSearchValueNull()"></span>
  </ng-template>
</div>