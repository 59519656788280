import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Codebook } from 'src/app/codebooks/codebook.models';
import { CodebookServices } from 'src/app/codebooks/codebook.services';
import { Documento, Mediazione } from 'src/app/generated/mediazione/models';
import { AnagraficaService } from 'src/app/generated/mediazione/services';
import { UploaderComponent } from '../../uploader/uploader.component';
import { UtilsService } from 'src/app/shared/services/utils.service';

export interface AvvocatoFormData {
  nome?: string;
  cognome?: string;
  dataNascita?: string;
  cittaNascita?: string;
  numeroTelefono?: string;
  email?: string;
  pec?: string;
  provinciaNascita?: string;
  codiceFiscale?: string;
  capResidenza?: string;
  cittaResidenza?: string;
  indirizzoResidenza?: string;
  tipoPersona?: string;
  provinciaResidenza?: string;
  denominazione?: string;
  documenti?: Array<Documento>;
  idDocumentiInput?: Array<string>;
  rappresentata?: boolean;
  albo?: string;
  ordine?: string;
}

@Component({
  selector: 'app-avvocato-form',
  templateUrl: './avvocato-form.component.html',
  styleUrls: ['./avvocato-form.component.scss'],
})
export class AvvocatoFormComponent implements OnInit, AfterViewInit {
  form!: UntypedFormGroup;
  provinceList: Codebook[];
  statiList: Codebook[];
  comuniList: Codebook[];
  filteredProvinciaNascitaOptions: Codebook[];
  listAnagrafica: any[] = [];
  isCodiceFiscaleSelected: boolean = false;
  keyDecimal: boolean = false;
  idParte: string;
  isDataSelected = false;
  documentControl: AbstractControl;

  @Input() formData: AvvocatoFormData;
  @Input() isItFromParte: boolean;
  @Input() shouldBeAllDisabled: boolean;
  @Input() isNewlyAdded: boolean;
  @Input() wasCodiceFiscaleDisabledBefore: boolean;
  @Input() costituito: boolean;
  @Input() isAdesione: boolean;
  @Input() mediazione: Mediazione;
  @Input() isANewLawyerBeingAdded: boolean;

  @Output() onChangePersonTypeToGiuridica: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('uploader') uploader: UploaderComponent;

  constructor(
    private fb: UntypedFormBuilder,
    private codebookServices: CodebookServices,
    private anagraficaService: AnagraficaService,
    private cdref: ChangeDetectorRef,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.utilsService.getDropdownValues().subscribe(({ comuni, province, stati }) => {
      this.comuniList = comuni;
      this.provinceList = province;
      this.statiList = stati;
    });
    this.initForm();

    if (!this.isItFromParte && !this.shouldBeAllDisabled) {
      this.form.get('documenti').addValidators(Validators.required);
    } else {
      this.form.addControl('rappresentata', new FormControl(false));
      this.form.get('rappresentata').setValue(false);
    }
    this.documentControl = this.form.get('documenti');

    if (this.formData) {
      this.form.patchValue({ ...this.formData });
      if (this.isItFromParte) {
        this.form.get('documenti').setValue(null);
      }
    }

    if (this.shouldBeAllDisabled && !this.isANewLawyerBeingAdded) {
      this.toggleFields(null, true);
    } else {
      if (this.wasCodiceFiscaleDisabledBefore) {
        this.toggleFields(['codiceFiscale'], true);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.formData) {
      // this.patchDocuments()
      this.cdref.detectChanges();
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      nome: [{ value: null, disabled: false }, Validators.required],
      cognome: [{ value: null, disabled: false }, Validators.required],
      dataNascita: [{ value: null, disabled: false }],
      cittaNascita: [{ value: null, disabled: false }],
      numeroTelefono: [{ value: null, disabled: false }],
      email: [{ value: null, disabled: false }, [Validators.email, Validators.required]],
      pec: [{ value: null, disabled: false }, this.isItFromParte ? [Validators.email] : [Validators.email, Validators.required]],
      provinciaNascita: [{ value: null, disabled: false }],
      codiceFiscale: [
        { value: null, disabled: false },
        [
          Validators.pattern(/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i),
          Validators.required,
        ],
      ],
      capResidenza: [
        { value: null, disabled: false },
        Validators.pattern('^[0-9]{5}$'),
      ],
      cittaResidenza: [{ value: null, disabled: false }],
      indirizzoResidenza: [{ value: null, disabled: false }],
      tipoPersona: [{ value: 'PersonaFisica', disabled: false }],
      documenti: [null],
      albo: [{ value: null, disabled: false }],
      ordine: [{ value: null, disabled: false }],
    });
  }


  onChangePersona(decision: string) {
    if (decision === 'PersonaFisica') {
    } else {
      this.initForm();
      this.onChangePersonTypeToGiuridica.emit(true);
    }
  }

  onTypingCompileCodiceFiscale(cf: string, event: any) {
    const key = event.inputType;
    console.log(' typing avv '); /*
    if (this.isAdesione) {
      if (cf !== '') {
        const pfs = this.mediazione.parti?.filter(pp => pp.datiAnagrafici.tipoPersona === 'PersonaFisica')?.filter(pp => pp.tipo !== 'ESTROMESSO');;
        const filtered: Parte[] = pfs?.filter(pp => pp.datiAnagrafici.codiceFiscale?.toLowerCase()?.includes(cf.toLowerCase()));
        const list: any[] = filtered?.filter(pp => (pp.tipo === 'RESISTENTE' || pp.tipo === 'CHIAMATO') && !pp.costituito);
        this.listAnagrafica = list.map(ll => { return { ...ll.datiAnagrafici, id: ll.id } });
        console.log(" list ", this.listAnagrafica);
      }
    } else {*/
    if (cf !== '') {
      this.anagraficaService
        .lookupAnagrafica({
          tipoPersona: 'PersonaFisica',
          filter: { cfOrPiva: cf },
        })
        .subscribe((res) => {
          this.listAnagrafica = res.list;
        });
    }
    //}

    if (!this.form.get('codiceFiscale').valid) {
      if (key === 'deleteContentBackward') {
        this.toggleFields(['codiceFiscale'], false, null, null, key);
      } else {
        this.toggleFields(['codiceFiscale'], false);
      }
    }
  }

  onTypingCognome(cognome: string) {
    if (this.isDataSelected) {
      console.log(' test ');
      this.toggleFields(['cognome'], false);
    } /*
    if (this.isAdesione) {
      if (cognome !== '') {
        const partiAny: any[] = this.mediazione.parti.filter(pp => pp.tipo !== 'ESTROMESSO');;
        const pfs = partiAny?.filter(pp => pp.datiAnagrafici.tipoPersona === 'PersonaFisica');
        const filtered = pfs?.filter(pp => pp.datiAnagrafici.cognome?.toLowerCase()?.includes(cognome.toLowerCase()));
        const list: any[] = filtered?.filter(pp => (pp.tipo === 'RESISTENTE' || pp.tipo === 'CHIAMATO') && !pp.costituito);
        this.listAnagrafica = list.map(ll => { return { ...ll.datiAnagrafici, id: ll.id } });
      }
    } else { */
    if (cognome !== '') {
      this.anagraficaService
        .lookupAnagrafica({
          filter: {
            cognomeOrDenominazione: cognome,
          },
          tipoPersona: 'PersonaFisica',
        })
        .subscribe((res) => {
          this.listAnagrafica = res.list;
        });
    }
    //}
  }

  toggleFields(
    exeption: string[],
    shouldDisable: boolean,
    field: string = null,
    id?: string,
    event?: string
  ) {
    if (field && id) {
      const selected = this.listAnagrafica.filter((ff) => ff.id === id);

      if (this.isAdesione) {
        this.idParte = id;
      }

      if (selected && selected[0]) {
        this.isDataSelected = true;
        this.form.patchValue(selected[0], {
          emitEvent: false,
          onlySelf: true,
        });
      }

      if (field !== 'codiceFiscale') {
        this.form.get('codiceFiscale').updateValueAndValidity();
      }

      this.form.get('documenti').markAsPristine();

      this.listAnagrafica = [];
    } else {
      if (this.isAdesione) {
        this.idParte = undefined;
      }
      this.isDataSelected = false;
    }

    if (shouldDisable) {
      this.isCodiceFiscaleSelected = true;
    } else {
      this.isCodiceFiscaleSelected = false;
    }

    Object.keys(this.form.controls).forEach((key: string) => {
      if (!exeption?.includes(key)) {
        if (shouldDisable) {
          if (key !== 'documenti') {
            if (this.isItFromParte) {
              if (key !== 'rappresentata') {
                this.form.get(key).disable();
              }
            } else {
              this.form.get(key).disable();
            }
          }
        } else {
          if (this.isAdesione) {
            if (key === 'cognome' || key === 'tipoPersona') {
              this.form.get(key).enable();
            }
          } else {
            if (key !== 'documenti') {
              if (this.isItFromParte) {
                if (key !== 'rappresentata') {
                  this.form.get(key).enable();
                }
              } else {
                this.form.get(key).enable();
              }
            }
          }
          if (key === 'documenti') {
            if (this.isItFromParte) {
              if (this.form.get('documenti').value) {
                for (const iterator of this.form.get('documenti').value) {
                  this.uploader.handleRemove({
                    name: iterator.nomeFile,
                    uid: iterator.objectKey,
                  });
                }
                this.uploader.formdata.delete('files');
                this.uploader.files = [];
                this.form.get('documenti').setValue(null);
              }
            } else {
              if (this.form.get('documenti').value) {
                this.uploader.handleRemove({
                  name: this.form.get('documenti').value[0].nomeFile,
                  uid: this.form.get('documenti').value[0].objectKey,
                });
              }
              this.form.get('documenti').setValue(null);
              this.form.get('documenti').markAsPristine();
            }
          } else {
            if (key !== 'tipoPersona') {
              if (this.isItFromParte) {
                if (key !== 'rappresentata') {
                  this.form.get(key).reset();
                }
              } else {
                this.form.get(key).reset();
              }
            }
          }
        }
      }
    });
  }

  keyPressNumbers(event, field: string) {
    var perValue = this.form.get(field).value;

    var charCode = event.which ? event.which : event.keyCode;

    if (
      perValue === null ||
      perValue.toString().indexOf('.') == -1 ||
      Number.isInteger(perValue)
    ) {
      this.keyDecimal = false;
    }

    if (charCode < 45 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      if (charCode == 46 && !this.keyDecimal) {
        this.keyDecimal = true;
      } else if ((charCode == 46 && this.keyDecimal) || charCode == 47) {
        event.preventDefault();
        return false;
      }
      return true;
    }
  }

  public disableFieldsForAderente() {
    Object.keys(this.form.controls).forEach((key: string) => {
      if (
        ![
          'codiceFiscale',
          'partitaIva',
          'denominazione',
          'tipoPersona',
          'cognome',
        ].includes(key)
      ) {
        this.form.get(key).disable();
      } else {
        this.form.get(key).enable();
      }
    });
  }

  handleDocumentDeletion(uid: string) {
    console.log('delete');
    if (this.isItFromParte) {
      const filteredDocuments = this.form
        .get('documenti')
        .value.filter((ff) => ff.objectKey !== uid);
      this.form.get('documenti').setValue(filteredDocuments);
    } else {
      this.form.get('documenti').setValue(null);
    }
  }

  handleAddDocument(item: { idTemp: string; uidNz: string }) {
    console.log(' item ', item);
    if (this.isItFromParte) {
      if (!this.form.get('documenti').value) {
        this.form.get('documenti').setValue([]);
      }

      const filteredDocuments = this.form
        .get('documenti')
        .value.filter((ff) => ff.nomeFile !== item.idTemp);

      this.form.get('documenti').setValue(filteredDocuments);

      this.form
        .get('documenti')
        .setValue([
          ...this.form.get('documenti').value,
          { nomeFile: item.idTemp, objectKey: item.uidNz },
        ]);
    } else {
      this.form
        .get('documenti')
        .setValue([{ nomeFile: item.idTemp, objectKey: item.uidNz }]);
    }
  }

  /*
  patchDocuments() {
    if (this.isItFromParte) {
      for (const iterator of this.formData.documenti) {
        this.uploader.files.push({
          name: iterator.nomeFile,
          uid: iterator.objectKey,
          status: 'done',
          url: ''
        });
      }
    } else {
      this.uploader.allowMultiple = false
      this.uploader.files.push({
        name: this.formData.documenti[0].nomeFile,
        uid: this.formData.documenti[0].objectKey,
        status: 'done',
        url: ''
      });
    }


    console.log(this.uploader);

  }
  */
}
