/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CalcolaSpeseService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation calcolaSpese
   */
  static readonly CalcolaSpesePath = '/api/calcola/spese';

  /**
   * calcola le spese.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calcolaSpese()` instead.
   *
   * This method doesn't expect any request body.
   */
  calcolaSpese$Response(params: {

    /**
     * valore causa
     */
    valore: number;

    /**
     * tipologia
     */
    tipoObbligatoria: boolean;

    /**
     * tipospesa
     */
    tipoSpesa?: 1 | 2;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, CalcolaSpeseService.CalcolaSpesePath, 'get');
    if (params) {
      rb.query('valore', params.valore, {"style":"simple"});
      rb.query('tipoObbligatoria', params.tipoObbligatoria, {"style":"simple"});
      rb.query('tipoSpesa', params.tipoSpesa, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * calcola le spese.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calcolaSpese$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calcolaSpese(params: {

    /**
     * valore causa
     */
    valore: number;

    /**
     * tipologia
     */
    tipoObbligatoria: boolean;

    /**
     * tipospesa
     */
    tipoSpesa?: 1 | 2;
  },
  context?: HttpContext

): Observable<number> {

    return this.calcolaSpese$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

}
