import { Pipe, PipeTransform } from '@angular/core';

type Status = 'DA_ESAMINARE' | 'AVVIATA' | 'IN_CORSO' | 'CHIUSA' | 'SCARTATA';

@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {
  transform(value: Status, ...args: string[]): unknown {
    switch (value) {
      case 'AVVIATA':
        return args[0] === 'color' ? 'purple' : 'Avviata';
      case 'IN_CORSO':
        return args[0] === 'color' ? 'blue' : 'In corso';
      case 'CHIUSA':
        return args[0] === 'color' ? 'green' : 'Chiusa';
      case 'SCARTATA':
        return args[0] === 'color' ? 'magenta' : 'Scartata';
      default:
        return args[0] === 'color' ? 'orange' : 'Da esaminare';
    }
  }
}
