/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Documento } from '../models/documento';
import { DocumentoOutput } from '../models/documento-output';
import { Pagination } from '../models/pagination';
import { Sorting } from '../models/sorting';

@Injectable({
  providedIn: 'root',
})
export class UtenteDocumentiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDocumenti
   */
  static readonly GetDocumentiPath = '/api/documenti';

  /**
   * loads the documenti associated to the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumenti()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumenti$Response(params: {
    template: boolean;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DocumentoOutput>> {

    const rb = new RequestBuilder(this.rootUrl, UtenteDocumentiService.GetDocumentiPath, 'get');
    if (params) {
      rb.query('template', params.template, {"style":"form"});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DocumentoOutput>;
      })
    );
  }

  /**
   * loads the documenti associated to the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumenti$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumenti(params: {
    template: boolean;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<DocumentoOutput> {

    return this.getDocumenti$Response(params,context).pipe(
      map((r: StrictHttpResponse<DocumentoOutput>) => r.body as DocumentoOutput)
    );
  }

  /**
   * Path part for operation getDocumentiPrivacy
   */
  static readonly GetDocumentiPrivacyPath = '/api/documenti/privacy';

  /**
   * loads the last privacy doc uploaded.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentiPrivacy()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentiPrivacy$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Documento>> {

    const rb = new RequestBuilder(this.rootUrl, UtenteDocumentiService.GetDocumentiPrivacyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Documento>;
      })
    );
  }

  /**
   * loads the last privacy doc uploaded.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentiPrivacy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentiPrivacy(params?: {
  },
  context?: HttpContext

): Observable<Documento> {

    return this.getDocumentiPrivacy$Response(params,context).pipe(
      map((r: StrictHttpResponse<Documento>) => r.body as Documento)
    );
  }

  /**
   * Path part for operation deleteDoc
   */
  static readonly DeleteDocPath = '/api/documento/{id}';

  /**
   * delete doc.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDoc()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDoc$Response(params: {

    /**
     * doc Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UtenteDocumentiService.DeleteDocPath, 'delete');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * delete doc.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDoc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDoc(params: {

    /**
     * doc Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteDoc$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
