/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Evento } from '../models/evento';
import { IstanzaProsecuzione } from '../models/istanza-prosecuzione';
import { MateriaMediazione } from '../models/materia-mediazione';
import { Mediazione } from '../models/mediazione';
import { MediazioneOutput } from '../models/mediazione-output';
import { MediazioneSearch } from '../models/mediazione-search';
import { Nota } from '../models/nota';
import { Pagination } from '../models/pagination';
import { RichiestaDocumentale } from '../models/richiesta-documentale';
import { Sorting } from '../models/sorting';
import { Utente } from '../models/utente';
import { UtenteOutput } from '../models/utente-output';
import { UtenteSearch } from '../models/utente-search';

@Injectable({
  providedIn: 'root',
})
export class MediazioneSegretarioService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRichiestaDocumentaleByMediazione
   */
  static readonly GetRichiestaDocumentaleByMediazionePath = '/api/segretario/richiesteDocumentali/{id}';

  /**
   * loads the richieste documentali associated to the user with the mediazione id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRichiestaDocumentaleByMediazione()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRichiestaDocumentaleByMediazione$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RichiestaDocumentale>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.GetRichiestaDocumentaleByMediazionePath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RichiestaDocumentale>>;
      })
    );
  }

  /**
   * loads the richieste documentali associated to the user with the mediazione id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRichiestaDocumentaleByMediazione$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRichiestaDocumentaleByMediazione(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Array<RichiestaDocumentale>> {

    return this.getRichiestaDocumentaleByMediazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RichiestaDocumentale>>) => r.body as Array<RichiestaDocumentale>)
    );
  }

  /**
   * Path part for operation lookupMateriaSegretario
   */
  static readonly LookupMateriaSegretarioPath = '/api/segretario/mediazione/materia';

  /**
   * load the list of Materias for a Mediazione Obbligatoria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupMateriaSegretario()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupMateriaSegretario$Response(params?: {
    sort?: null | Sorting;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MateriaMediazione>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.LookupMateriaSegretarioPath, 'get');
    if (params) {
      rb.query('sort', params.sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MateriaMediazione>>;
      })
    );
  }

  /**
   * load the list of Materias for a Mediazione Obbligatoria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupMateriaSegretario$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupMateriaSegretario(params?: {
    sort?: null | Sorting;
  },
  context?: HttpContext

): Observable<Array<MateriaMediazione>> {

    return this.lookupMateriaSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MateriaMediazione>>) => r.body as Array<MateriaMediazione>)
    );
  }

  /**
   * Path part for operation findAllMediatore
   */
  static readonly FindAllMediatorePath = '/api/segretario/mediazione/mediatore';

  /**
   * get list of mediatori.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllMediatore()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllMediatore$Response(params?: {
    utente?: UtenteSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UtenteOutput>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.FindAllMediatorePath, 'get');
    if (params) {
      rb.query('utente', params.utente, {"explode":true});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UtenteOutput>;
      })
    );
  }

  /**
   * get list of mediatori.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllMediatore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllMediatore(params?: {
    utente?: UtenteSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<UtenteOutput> {

    return this.findAllMediatore$Response(params,context).pipe(
      map((r: StrictHttpResponse<UtenteOutput>) => r.body as UtenteOutput)
    );
  }

  /**
   * Path part for operation calculateMediatore
   */
  static readonly CalculateMediatorePath = '/api/mediazione/mediatore/automatico';

  /**
   * return the mediatore selected by the automatic algorithm.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateMediatore()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateMediatore$Response(params: {

    /**
     * Mediazione Id
     */
    idMediazione: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Utente>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.CalculateMediatorePath, 'get');
    if (params) {
      rb.query('idMediazione', params.idMediazione, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Utente>;
      })
    );
  }

  /**
   * return the mediatore selected by the automatic algorithm.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculateMediatore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateMediatore(params: {

    /**
     * Mediazione Id
     */
    idMediazione: string;
  },
  context?: HttpContext

): Observable<Utente> {

    return this.calculateMediatore$Response(params,context).pipe(
      map((r: StrictHttpResponse<Utente>) => r.body as Utente)
    );
  }

  /**
   * Path part for operation findAllMediazioneSegretario
   */
  static readonly FindAllMediazioneSegretarioPath = '/api/segretario/mediazione';

  /**
   * find all mediazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllMediazioneSegretario()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllMediazioneSegretario$Response(params?: {
    search?: MediazioneSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MediazioneOutput>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.FindAllMediazioneSegretarioPath, 'get');
    if (params) {
      rb.query('search', params.search, {"explode":true});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediazioneOutput>;
      })
    );
  }

  /**
   * find all mediazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllMediazioneSegretario$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllMediazioneSegretario(params?: {
    search?: MediazioneSearch;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<MediazioneOutput> {

    return this.findAllMediazioneSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<MediazioneOutput>) => r.body as MediazioneOutput)
    );
  }

  /**
   * Path part for operation getMediazioneSegretario
   */
  static readonly GetMediazioneSegretarioPath = '/api/segretario/mediazione/{id}';

  /**
   * loads the Mediazione associated with the mediazione id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediazioneSegretario()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediazioneSegretario$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mediazione>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.GetMediazioneSegretarioPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mediazione>;
      })
    );
  }

  /**
   * loads the Mediazione associated with the mediazione id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMediazioneSegretario$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediazioneSegretario(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Mediazione> {

    return this.getMediazioneSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mediazione>) => r.body as Mediazione)
    );
  }

  /**
   * Path part for operation getStoricoMediazioneSegretario
   */
  static readonly GetStoricoMediazioneSegretarioPath = '/api/segretario/mediazione/{id}/storico';

  /**
   * load the storico of specific Mediazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStoricoMediazioneSegretario()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStoricoMediazioneSegretario$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Evento>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.GetStoricoMediazioneSegretarioPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Evento>>;
      })
    );
  }

  /**
   * load the storico of specific Mediazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStoricoMediazioneSegretario$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStoricoMediazioneSegretario(params: {

    /**
     * Mediazione Id
     */
    id: string;
    sort?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<Array<Evento>> {

    return this.getStoricoMediazioneSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Evento>>) => r.body as Array<Evento>)
    );
  }

  /**
   * Path part for operation getIstanzeMediazioneSegretario
   */
  static readonly GetIstanzeMediazioneSegretarioPath = '/api/segretario/mediazione/{id}/istanze';

  /**
   * load the istanze of specific Mediazione.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIstanzeMediazioneSegretario()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIstanzeMediazioneSegretario$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<IstanzaProsecuzione>>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.GetIstanzeMediazioneSegretarioPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IstanzaProsecuzione>>;
      })
    );
  }

  /**
   * load the istanze of specific Mediazione.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIstanzeMediazioneSegretario$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIstanzeMediazioneSegretario(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Array<IstanzaProsecuzione>> {

    return this.getIstanzeMediazioneSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<IstanzaProsecuzione>>) => r.body as Array<IstanzaProsecuzione>)
    );
  }

  /**
   * Path part for operation findAllEsperto
   */
  static readonly FindAllEspertoPath = '/api/segretario/mediazione/esperto';

  /**
   * get list of esperti.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllEsperto()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllEsperto$Response(params?: {
    search?: UtenteSearch;
    sorting?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UtenteOutput>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.FindAllEspertoPath, 'get');
    if (params) {
      rb.query('search', params.search, {"explode":true});
      rb.query('sorting', params.sorting, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UtenteOutput>;
      })
    );
  }

  /**
   * get list of esperti.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllEsperto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllEsperto(params?: {
    search?: UtenteSearch;
    sorting?: null | Sorting;
    page?: null | Pagination;
  },
  context?: HttpContext

): Observable<UtenteOutput> {

    return this.findAllEsperto$Response(params,context).pipe(
      map((r: StrictHttpResponse<UtenteOutput>) => r.body as UtenteOutput)
    );
  }

  /**
   * Path part for operation addNotaSegretario
   */
  static readonly AddNotaSegretarioPath = '/api/segretario/note';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNotaSegretario()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNotaSegretario$Response(params?: {
    body?: Nota
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Nota>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.AddNotaSegretarioPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Nota>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addNotaSegretario$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNotaSegretario(params?: {
    body?: Nota
  },
  context?: HttpContext

): Observable<Nota> {

    return this.addNotaSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<Nota>) => r.body as Nota)
    );
  }

  /**
   * Path part for operation getNotaSegretarioByMediazione
   */
  static readonly GetNotaSegretarioByMediazionePath = '/api/segretario/note/{id}';

  /**
   * loads the nota associated to the user with the mediazione id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotaSegretarioByMediazione()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotaSegretarioByMediazione$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Nota>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.GetNotaSegretarioByMediazionePath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Nota>;
      })
    );
  }

  /**
   * loads the nota associated to the user with the mediazione id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotaSegretarioByMediazione$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotaSegretarioByMediazione(params: {

    /**
     * Mediazione Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Nota> {

    return this.getNotaSegretarioByMediazione$Response(params,context).pipe(
      map((r: StrictHttpResponse<Nota>) => r.body as Nota)
    );
  }

  /**
   * Path part for operation updateNotaSegretario
   */
  static readonly UpdateNotaSegretarioPath = '/api/segretario/note/{id}';

  /**
   * update nota.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotaSegretario()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotaSegretario$Response(params: {

    /**
     * Mediazione Id
     */
    id: string;
    body: Nota
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Nota>> {

    const rb = new RequestBuilder(this.rootUrl, MediazioneSegretarioService.UpdateNotaSegretarioPath, 'patch');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Nota>;
      })
    );
  }

  /**
   * update nota.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNotaSegretario$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotaSegretario(params: {

    /**
     * Mediazione Id
     */
    id: string;
    body: Nota
  },
  context?: HttpContext

): Observable<Nota> {

    return this.updateNotaSegretario$Response(params,context).pipe(
      map((r: StrictHttpResponse<Nota>) => r.body as Nota)
    );
  }

}
