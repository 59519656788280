import { Injectable } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Ruolo, Utente } from 'src/app/generated/mediazione/models';


@Injectable({
	providedIn: 'root'
})
export class CacheService {
	private _theme: string;
	private _error = new BehaviorSubject<any>(undefined);
	private _roles: Ruolo[];
	private _profileKC: KeycloakProfile;
	private _registrationPending: boolean;
	private _codiceFiscale: string;
	private _massivoInCorso: boolean;
	private _previousRoute: string;

	get theme(): string {
		return this._theme;
	}

	set theme(value: string) {
		this._theme = value;
	}

	get error(): BehaviorSubject<any> {
		return this._error;
	}

	set error(value: BehaviorSubject<any>) {
		this._error = value;
	}

	get roles(): Ruolo[] {
		return this._roles;
	}

	set roles(value: Ruolo[]) {
		this._roles = value;
	}

	get profileKC(): KeycloakProfile {
		return this._profileKC;
	}

	set profileKC(value: KeycloakProfile) {
		this._profileKC = value;
	}

	get registrationPending(): boolean {
		return this._registrationPending;
	}

	set registrationPending(value: boolean) {
		this._registrationPending = value;
	}

	get codiceFiscale(): string {
		return this._codiceFiscale;
	}

	set codiceFiscale(value: string) {
		this._codiceFiscale = value;
	}

	get massivoInCorso(): boolean {
		return this._massivoInCorso;
	}

	set massivoInCorso(value: boolean) {
		this._massivoInCorso = value;
	}

	get previousRoute(): string {
		return this._previousRoute;
	}

	set previousRoute(value: string) {
		this._previousRoute = value;
	}

	constructor() { }
}

