import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from './../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {


	constructor(private injector: Injector) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const tenant = localStorage.getItem('realm');
		if (tenant) {
			req = req.clone({
				headers: req.headers.append('X-Tenant-Id', tenant)
			});
		}

		return next.handle(req);
	}
}
