<nz-collapse nzAccordion>
  <nz-collapse-panel
    *ngFor="let panel of panels; let index = index"
    [nzHeader]="headerTmpl"
    [(nzActive)]="panel.active"
    [nzExtra]="panel.canBeDeleted ? extraTpl : null"
  >
    <div class="row px-1">
      <ng-container *ngIf="panel.isParte">
        <app-parte-form
          class="w-100"
          [mediazione]="mediazione"
          [formData]="parentIndex !== null ? panel.formData : null"
          [shouldBeAllDisabled]="shouldBeAllDisabled"
          [costituito]="panel.costituito"
          [wasCodiceFiscaleDisabledBefore]="
            panel.wasCodiceFiscaleDisabledBefore
          "
          [isAdesione]="isAdesione"
          #parteForm
        ></app-parte-form>
      </ng-container>

      <ng-container *ngIf="!panel.isParte">
        <app-avvocato-form
          class="w-100"
          [formData]="parentIndex !== null ? panel.formData : null"
          [shouldBeAllDisabled]="shouldBeAllDisabled"
          [isNewlyAdded]="panel.canBeDeleted"
          [wasCodiceFiscaleDisabledBefore]="
            panel.wasCodiceFiscaleDisabledBefore
          "
          [isANewLawyerBeingAdded]="panel.isANewLawyerBeingAdded"
          #avvocatoForm
        ></app-avvocato-form>
      </ng-container>
    </div>
    <ng-template #extraTpl>
      <span
        nz-icon
        nzType="delete"
        (click)="removeLawyer($event, index)"
      ></span>
    </ng-template>
    <ng-template #headerTmpl>
      <ng-container *ngIf="panel?.formData?.tipoPersona === null">
        <span nz-typography
          ><strong>{{ panel.name }}</strong></span
        >
      </ng-container>
      <ng-container *ngIf="panel?.formData?.tipoPersona === 'PersonaFisica'">
        <span nz-typography>
          <strong>{{ panel.name }} </strong> -
          {{ panel?.formData?.nome + " " + panel?.formData?.cognome }}
          {{ " (" + panel?.formData?.codiceFiscale + ")" }}</span
        >
      </ng-container>
      <ng-container *ngIf="panel?.formData?.tipoPersona === 'PersonaGiuridica'">
        <span nz-typography>
          <strong>{{ panel.name }} </strong> -
          {{ panel?.formData?.denominazione + " " }}
          {{ " (" + panel?.formData?.partitaIva + ")" }}</span
        >
      </ng-container>
    </ng-template>
  </nz-collapse-panel>
</nz-collapse>
