<div class="d-flex justify-content-end calendar-header">
  <span
    nz-icon
    nzType="step-backward"
    nzTheme="outline"
    (click)="goToPreviousDays()"
  ></span>
  <span
    nz-icon
    nzType="step-forward"
    nzTheme="outline"
    (click)="goToNextDays()"
  ></span>
</div>
<table role="grid" class="table-content">
  <thead class="w-100">
    <tr role="row">
      <th *ngFor="let day of daysOfTheWeek" role="columnheader" [title]="day">
        {{ day }}
      </th>
    </tr>
  </thead>
  <tbody class="w-100">
    <tr role="row">
      <td
        *ngFor="let date of dateList"
        role="gridcell"
        class="table-cell"
        (click)="onClickCell(date)"
      >
        <div class="calendar-date">
          <div class="calendar-date-value">
            {{ date.value | date : "dd/MM/yyyy" }}
          </div>
          <nz-spin [nzSpinning]="loading">
            <div class="calendar-date-content">
              <ul *ngIf="date.areThereIncontri">
                <ng-container
                  *ngFor="let incontro of date.incontriList; let index = index"
                >
                  <li *ngIf="index < 3">
                    <nz-badge
                      nzColor="cyan"
                      [nzText]="incontro.dettagli"
                    ></nz-badge>
                  </li>
                </ng-container>
              </ul>
            </div>
          </nz-spin>
        </div>
      </td>
    </tr>
  </tbody>
</table>
