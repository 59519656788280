import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MateriaMediazione, TipoPersonaGiuridica } from '../generated/mediazione/models';
import { AnagraficaService, MediazioneSegretarioService, MediazioneUtentiService } from '../generated/mediazione/services';
import { Codebook } from './codebook.models';

@Injectable({
  providedIn: 'root',
})
export class CodebookServices {
  constructor(
    private http: HttpClient,
    private anagraficaService: AnagraficaService,
    private mediazioneSegretarioService: MediazioneSegretarioService,
    private mediazioneUtentiService: MediazioneUtentiService,
  ) { }

  getProvinces(): Observable<Codebook[]> {
    const url = '../../assets/province.json';
    return this.http.get(url).pipe(
      switchMap((data: Codebook[]) => {
        return of(data);
      })
    );
  }

  getStati(): Observable<Codebook[]> {
    const url = '../../assets/stati.json';
    return this.http.get(url).pipe(
      switchMap((data: Codebook[]) => {
        return of(data);
      })
    );
  }

  getComuni(): Observable<Codebook[]> {
    const url = '../../assets/comuni.json';
    return this.http.get(url).pipe(
      switchMap((data: Codebook[]) => {
        return of(data);
      })
    );
  }

  getTipologiaGiuridica(): Observable<TipoPersonaGiuridica[]> {
    return this.anagraficaService.lookupTipoPg().pipe(
      switchMap((data: TipoPersonaGiuridica[]) => {
        return of(data);
      })
    );
  }

  getMaterieSegretario(): Observable<MateriaMediazione[]> {
    return this.mediazioneSegretarioService.lookupMateriaSegretario().pipe(
      switchMap((data: MateriaMediazione[]) => {
        return of(data);
      })
    );
  }

  getMaterieUtenti(): Observable<MateriaMediazione[]> {
    return this.mediazioneUtentiService.lookupMateriaUtenti().pipe(
      switchMap((data: MateriaMediazione[]) => {
        return of(data);
      })
    );
  }
  
}
