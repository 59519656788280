/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MediazioneParziale } from '../models/mediazione-parziale';

@Injectable({
  providedIn: 'root',
})
export class UtentePubblicoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPublicMediazioneByProtocollo
   */
  static readonly GetPublicMediazioneByProtocolloPath = '/api/mediazione/pubblico/nProtocollo';

  /**
   * loads the Mediazione associated with the protocollo id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicMediazioneByProtocollo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicMediazioneByProtocollo$Response(params: {

    /**
     * numero Protocollo
     */
    nProtocollo: string;

    /**
     * anno Protocollo
     */
    annoProtocollo: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MediazioneParziale>>> {

    const rb = new RequestBuilder(this.rootUrl, UtentePubblicoService.GetPublicMediazioneByProtocolloPath, 'get');
    if (params) {
      rb.query('nProtocollo', params.nProtocollo, {"style":"simple"});
      rb.query('annoProtocollo', params.annoProtocollo, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MediazioneParziale>>;
      })
    );
  }

  /**
   * loads the Mediazione associated with the protocollo id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicMediazioneByProtocollo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicMediazioneByProtocollo(params: {

    /**
     * numero Protocollo
     */
    nProtocollo: string;

    /**
     * anno Protocollo
     */
    annoProtocollo: string;
  },
  context?: HttpContext

): Observable<Array<MediazioneParziale>> {

    return this.getPublicMediazioneByProtocollo$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MediazioneParziale>>) => r.body as Array<MediazioneParziale>)
    );
  }

}
