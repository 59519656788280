/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Anagrafica } from '../models/anagrafica';
import { Disponibilita } from '../models/disponibilita';

@Injectable({
  providedIn: 'root',
})
export class SegretarioUtenteService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAnagraficaByid
   */
  static readonly GetAnagraficaByidPath = '/api/anagrafica/{id}';

  /**
   * return a specific anagrafica.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnagraficaByid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnagraficaByid$Response(params: {

    /**
     * Anagrafica Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Anagrafica>> {

    const rb = new RequestBuilder(this.rootUrl, SegretarioUtenteService.GetAnagraficaByidPath, 'get');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Anagrafica>;
      })
    );
  }

  /**
   * return a specific anagrafica.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAnagraficaByid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnagraficaByid(params: {

    /**
     * Anagrafica Id
     */
    id: string;
  },
  context?: HttpContext

): Observable<Anagrafica> {

    return this.getAnagraficaByid$Response(params,context).pipe(
      map((r: StrictHttpResponse<Anagrafica>) => r.body as Anagrafica)
    );
  }

  /**
   * Path part for operation updateAnagrafica
   */
  static readonly UpdateAnagraficaPath = '/api/anagrafica/{id}';

  /**
   * anagrafica.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnagrafica()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnagrafica$Response(params: {

    /**
     * Anagrafica Id
     */
    id: string;
    body: Anagrafica
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Anagrafica>> {

    const rb = new RequestBuilder(this.rootUrl, SegretarioUtenteService.UpdateAnagraficaPath, 'put');
    if (params) {
      rb.path('id', params.id, {"style":"simple"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Anagrafica>;
      })
    );
  }

  /**
   * anagrafica.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnagrafica$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnagrafica(params: {

    /**
     * Anagrafica Id
     */
    id: string;
    body: Anagrafica
  },
  context?: HttpContext

): Observable<Anagrafica> {

    return this.updateAnagrafica$Response(params,context).pipe(
      map((r: StrictHttpResponse<Anagrafica>) => r.body as Anagrafica)
    );
  }

  /**
   * Path part for operation getDisponibilitaUtente
   */
  static readonly GetDisponibilitaUtentePath = '/api/segretario/utente/disponibilita';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDisponibilitaUtente()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDisponibilitaUtente$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Disponibilita>>> {

    const rb = new RequestBuilder(this.rootUrl, SegretarioUtenteService.GetDisponibilitaUtentePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Disponibilita>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDisponibilitaUtente$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDisponibilitaUtente(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Disponibilita>> {

    return this.getDisponibilitaUtente$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Disponibilita>>) => r.body as Array<Disponibilita>)
    );
  }

  /**
   * Path part for operation setDisponibilitaUtente
   */
  static readonly SetDisponibilitaUtentePath = '/api/segretario/utente/disponibilita';

  /**
   * save disponibilita.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDisponibilitaUtente()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDisponibilitaUtente$Response(params: {
    body: Array<Disponibilita>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SegretarioUtenteService.SetDisponibilitaUtentePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * save disponibilita.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setDisponibilitaUtente$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDisponibilitaUtente(params: {
    body: Array<Disponibilita>
  },
  context?: HttpContext

): Observable<void> {

    return this.setDisponibilitaUtente$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
